import React from 'react'
import { Box, VStack, Text, Icon, Flex } from '@chakra-ui/react'
import IonIcon from 'src/atoms/IonIcon'

interface RecipientInfoProps {
  recipientName: string
  recipientEmail: string
  recipientPhone: string
}

const RecipientInfo: React.FC<RecipientInfoProps> = ({
  recipientName,
  recipientEmail,
  recipientPhone,
}) => {
  return (
    <Box role='group' aria-labelledby='recipient-info-label' width='full'>
      <Flex align='center' mb={2} gap={2}>
        <Icon
          as={IonIcon}
          name='person-outline'
          color='astraBlue.200'
          size={15}
          aria-hidden='true'
        />
        <Text id='recipient-info-label' fontSize='sm' fontWeight='600'>
          Recipient Information
        </Text>
      </Flex>
      <VStack spacing={2} align='start'>
        <Flex align='center' w='full' gap={3}>
          <Icon
            as={IonIcon}
            name='information-circle-outline'
            color='gray.200'
            size={15}
            aria-hidden='true'
          />
          <Text color='gray.700' fontSize='sm'>
            {recipientName}
          </Text>
        </Flex>
        <Flex align='center' w='full' gap={3}>
          <Icon
            as={IonIcon}
            name='mail-outline'
            color='gray.200'
            size={15}
            aria-hidden='true'
          />
          <Text color='gray.600' fontSize='sm'>
            {recipientEmail}
          </Text>
        </Flex>
        <Flex align='center' w='full' gap={3}>
          <Icon
            as={IonIcon}
            name='call-outline'
            color='gray.200'
            size={15}
            aria-hidden='true'
          />
          <Text color='gray.600' fontSize='sm'>
            {recipientPhone || '(XXX) XXX-XXXX'}
          </Text>
        </Flex>
      </VStack>
    </Box>
  )
}

export default RecipientInfo
