import { extendTheme } from '@chakra-ui/theme-utils'

const palette = {
  almostBlack: '#1D1D26',
  almostWhite: '#f0ecec',
  blue: '#042E8F',
  errorRed: '#FC5F5F',
  gray: '#c2c6cc',
  grayDark: '#5C5C5C',
  grayLight: '#dddddd',
  grayMedium: '#707080',
  green: '#47D665',
  offWhite: '#f7f8fd',
  purple: '#593d89',
  red: '#E60000',
  superBlack: '#1D1D26',
  white: '#ffffff',
  yellow: '#FFFF00',
}

const riskLevels = {
  veryLowRisk: '#47D665',
  lowRisk: '#A7FF01',
  mediumRisk: '#FFE901',
  highRisk: '#FF7D01',
  veryHighRisk: '#E60000',
}

const astraBranding = {
  // Primary Colors
  ice: '#B5FFFE',
  aqua: '#F3FAFA',
  mist: '#F7FBFB',
  smoke: '#7B888A',
  midnight: '#03171A',
  white: '#FFFFFF',

  // Secondary - Greens
  malachite: '#4CCA19',
  emerald: '#2AA40C',
  sage: '#347C28',
  mineral: '#3E5744',
  cardin: '#05290D',

  // Secondary - Teals
  turquoise: '#39E2E0',
  pelorous: '#32A9C2',
  oracle: '#266F79',
  slate: '#325960',
  dusk: '#1B4247',
  oxide: '#1C3135',

  // Secondary - Blues
  sail: '#CDE4FF',
  topaz: '#3B8FF2',
  azure: '#224DD5',
  sapphire: '#314793',

  // Secondary - Reds
  rose: '#F4EAF1',
  garnet: '#6F064F',
  peach: '#FCF0ED',
  citrine: '#992004',

  // Tertiary
  gray: '#E6E8E8',
  dust: '#FAFAFB',
  haze: '#F2F9F9',
  oat: '#F5F5F1',
  stone: '#FBFAF7',
  lime: '#F5FBED',
  slateGray: '#E5ECEA',

  // Secondary - Yellows
  sunflower: '#FFC312',
  mustard: '#FFB142',
  paleYellow: '#FFF8E1',
}

const theme = {
  colors: {
    accent: palette.purple,
    amber: '#E67E22',
    background: palette.white,
    backgroundTinted: palette.offWhite,
    backgroundTintedLight: palette.almostWhite,
    border: palette.grayLight,
    borderDark: palette.grayDark,
    link: '#6495ED',
    // primary: palette.blue,
    text: palette.almostBlack,
    gray: '#787878',
    textInverse: palette.white,
    textSubtle: palette.grayMedium,
    success: palette.green,
    failure: palette.red,
    error: palette.errorRed,
    warning: palette.yellow,
    info: palette.blue,
    veryLowRisk: riskLevels.veryLowRisk,
    lowRisk: riskLevels.lowRisk,
    mediumRisk: riskLevels.mediumRisk,
    highRisk: riskLevels.highRisk,
    veryHighRisk: riskLevels.veryHighRisk,
    disabled: astraBranding.gray,
    white: palette.white,

    primary: '#0B2828',
    primaryLight: '#1E3535',

    astraRed100: astraBranding.peach,
    astraRed200: astraBranding.rose,
    astraRed300: astraBranding.citrine,
    astraRed400: astraBranding.garnet,

    astraBlue100: astraBranding.sail,
    astraBlue200: astraBranding.topaz,
    astraBlue300: astraBranding.azure,
    astraBlue400: astraBranding.sapphire,

    astraTeal100: astraBranding.turquoise,
    astraTeal200: astraBranding.pelorous,
    astraTeal300: astraBranding.oracle,
    astraTeal400: astraBranding.slate,
    astraTeal500: astraBranding.dusk,
    astraTeal600: astraBranding.oxide,

    astraGreen100: astraBranding.malachite,
    astraGreen200: astraBranding.emerald,
    astraGreen300: astraBranding.mineral,
    astraGreen400: astraBranding.cardin,

    astraTertiary100: astraBranding.gray,
    astraTertiary200: astraBranding.dust,
    astraTertiary300: astraBranding.haze,
    astraTertiary400: astraBranding.oat,
    astraTertiary500: astraBranding.stone,
    astraTertiary600: astraBranding.lime,
    astraTertiary700: astraBranding.slateGray,

    astraYellow100: astraBranding.sunflower,
    astraYellow200: astraBranding.mustard,
    astraYellow300: astraBranding.paleYellow,

    astraGrey100: astraBranding.mist,
    astraGrey200: astraBranding.smoke,
    astraGrey300: astraBranding.midnight,
  },
  fonts: {
    light: 'Avenir-Light',
    book: 'Avenir-Book',
    medium: 'Avenir-Medium',
    heavy: 'Avenir-Heavy',
  },
  text: {
    xxsmall: {
      size: '.7em',
      height: '1.3em',
    },
    xsmall: {
      size: '.8em',
      height: '1.5em',
    },
    small: {
      size: '.9em',
      height: '1.5em',
    },
    medium: {
      size: '1em',
      height: '1.5em',
    },
    large: {
      size: '1.2em',
      height: '1.5em',
    },
    xlarge: {
      size: '1.5em',
      height: '1.5em',
    },
    xxlarge: {
      size: '2em',
      height: '1.5em',
    },
  },
  spinner: {
    small: '30px',
    medium: '48px',
    large: '75px',
  },
}

const chakraColorScheme = {
  primary: {
    500: theme.colors.primary,
  },
  astraGreen: {
    100: theme.colors.astraGreen100,
    200: theme.colors.astraGreen200,
    300: theme.colors.astraGreen300,
    400: theme.colors.astraGreen400,
  },
  astraRed: {
    100: theme.colors.astraRed100,
    200: theme.colors.astraRed200,
    300: theme.colors.astraRed300,
    400: theme.colors.astraRed400,
  },
  astraBlue: {
    100: theme.colors.astraBlue100,
    200: theme.colors.astraBlue200,
    300: theme.colors.astraBlue300,
    400: theme.colors.astraBlue400,
  },
  astraTeal: {
    100: theme.colors.astraTeal100,
    200: theme.colors.astraTeal200,
    300: theme.colors.astraTeal300,
    400: theme.colors.astraTeal400,
    500: theme.colors.astraTeal500,
    600: theme.colors.astraTeal600,
  },
  astraTertiary: {
    100: theme.colors.astraTertiary100,
    200: theme.colors.astraTertiary200,
    300: theme.colors.astraTertiary300,
    400: theme.colors.astraTertiary400,
    500: theme.colors.astraTertiary500,
    600: theme.colors.astraTertiary600,
    700: theme.colors.astraTertiary700,
  },
  gray: {
    100: palette.almostWhite,
    200: theme.colors.astraGrey200,
    300: palette.gray,
    400: palette.grayDark,
  },
}

export const chakraTheme = extendTheme({
  colors: chakraColorScheme,
  fonts: theme.fonts,
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  components: {
    Alert: {
      variants: {
        subtle: () => ({
          container: {
            bg: 'white',
            boxShadow: 'md',
          },
        }),
        success: () => ({
          container: {
            bg: astraBranding.sage,
            color: 'white',
          },
        }),
        error: () => ({
          container: {
            bg: theme.colors.astraRed300,
            color: 'white',
          },
        }),
        warning: () => ({
          container: {
            bg: theme.colors.astraYellow100,
            color: 'black',
          },
        }),
        info: () => ({
          container: {
            bg: theme.colors.astraBlue200,
            color: 'white',
          },
        }),
        loading: () => ({
          container: {
            bg: theme.colors.astraTeal300,
            color: 'white',
          },
        }),
      },
    },
    Accordion: {
      parts: ['button', 'container'],
      baseStyle: {
        button: {
          backgroundColor: theme.colors.backgroundTintedLight,
          _hover: {
            backgroundColor: theme.colors.backgroundTinted,
          },
        },
        container: {
          border: 'none',
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 4,
        fontWeight: 'medium',
        boxSizing: 'border-box',
        cursor: 'pointer',
        _disabled: {
          cursor: 'not-allowed',
          _hover: {
            bg: 'initial',
            color: 'initial',
          },
        },
      },
      sizes: {
        lg: {
          fontSize: 'md',
          padding: '0.875rem 1.25rem',
          minWidth: 200,
        },
        md: {
          fontSize: 'md',
          padding: '0.75rem 1rem',
          minWidth: 100,
        },
        sm: {
          fontSize: 'sm',
          padding: '0.5rem 0.5rem',
          minWidth: 20,
        },
        xs: {
          fontSize: 'xs',
          padding: '0.5rem 0.5rem',
          minWidth: 10,
        },
        full: {
          width: '100%',
          padding: '1rem',
        },
      },
      variants: {
        primary: {
          bg: theme.colors.primary,
          color: theme.colors.textInverse,
          _hover: {
            opacity: 0.9,
          },
          _disabled: {
            _hover: {
              backgroundColor: `${theme.colors.primary} !important`,
              color: `${theme.colors.white} !important`,
              opacity: 0.3,
            },
          },
        },
        secondary: {
          bg: theme.colors.white,
          border: '1px solid',
          borderColor: theme.colors.primary,
          color: theme.colors.primary,
          _hover: {
            backgroundColor: theme.colors.primary,
            color: theme.colors.white,
          },
          _disabled: {
            _hover: {
              backgroundColor: `${theme.colors.white} !important`,
              color: `${theme.colors.primary} !important`,
              opacity: 0.3,
            },
          },
        },
        red: {
          bg: 'red.500',
          border: '1px solid',
          borderColor: 'red.500',
          color: theme.colors.textInverse,
          _hover: {
            opacity: 0.8,
          },
          _disabled: {
            _hover: {
              backgroundColor: `${theme.colors.white} !important`,
              color: `${theme.colors.primary} !important`,
              opacity: 0.3,
            },
          },
        },
        green: {
          bg: 'green.500',
          border: '1px solid',
          borderColor: 'green.500',
          color: theme.colors.textInverse,
          _hover: {
            opacity: 0.8,
          },
          _disabled: {
            _hover: {
              backgroundColor: `${theme.colors.white} !important`,
              color: `${theme.colors.primary} !important`,
              opacity: 0.3,
            },
          },
        },
      },
    },
    Code: {
      baseStyle: {
        bg: theme.colors.primaryLight,
        color: 'white',
        fontSize: 'sm',
        fontFamily: 'mono',
      },
      variants: {
        solid: {
          bg: theme.colors.primaryLight,
          color: 'white',
        },
      },
      defaultProps: {
        variant: 'solid',
      },
    },
    Tabs: {
      baseStyle: {
        tab: {
          _selected: {
            bg: 'astraGreen.300',
            color: 'white',
          },
          _hover: {
            bg: 'astraGreen.300',
            color: 'white',
          },
        },
      },
      sizes: {
        sm: {
          tab: {
            fontSize: '0.6rem',
            padding: '0.4rem 0.6rem',
          },
        },
      },
      variants: {
        'solid-rounded': {
          tab: {
            border: '1px solid',
            borderColor: 'astraTeal.400',
            color: 'astraTeal.400',
            _selected: {
              bg: 'astraTeal.400',
              color: 'white',
              borderColor: 'astraTeal.400',
            },
            _hover: {
              bg: 'astraTeal.400',
              color: 'white',
            },
          },
        },
        enclosed: {
          tab: {
            _selected: {
              bg: 'astraTeal.400',
              color: 'white',
            },
            _hover: {
              bg: 'astraTeal.400',
              color: 'white',
            },
          },
        },
      },
    },
    Tag: {
      baseStyle: {
        container: {
          borderRadius: '5px',
          fontSize: '16px',
          minWidth: 50,
        },
      },
      sizes: {
        lg: {
          fontSize: 'medium',
          padding: '0.875rem 1.25rem',
          minWidth: 200,
        },
        md: {
          fontSize: 'medium',
          padding: '1rem',
          minWidth: 100,
        },
        sm: {
          fontSize: 'small',
          padding: '0.5rem 0.5rem',
          minWidth: 20,
        },
        xs: {
          fontSize: 'xsmall',
          padding: '0.5rem 0.5rem',
          minWidth: 10,
        },
        full: {
          width: '100%',
          padding: '1rem',
        },
      },
      variants: {
        primary: {
          container: {
            bg: 'mediumGray',
            border: `1px solid ${theme.colors.borderDark}`,
            color: 'superBlack',
          },
        },
        lightBlue: {
          container: {
            bg: theme.colors.astraBlue200,
            border: `1px solid ${theme.colors.astraBlue200}`,
            color: 'white',
          },
        },
        yellow: {
          container: {
            bg: theme.colors.amber,
            border: `1px solid ${theme.colors.amber}`,
            color: 'white',
          },
        },
        green: {
          container: {
            bg: theme.colors.astraGreen300,
            border: `1px solid ${theme.colors.astraGreen300}`,
            color: 'white',
          },
        },

        red: {
          container: {
            bg: theme.colors.astraRed300,
            border: `1px solid ${theme.colors.astraRed300}`,
            color: 'white',
          },
        },
        blue: {
          container: {
            bg: theme.colors.astraBlue400,
            border: `1px solid ${theme.colors.astraBlue400}`,
            color: 'white',
          },
        },
        gray: {
          container: {
            bg: theme.colors.astraGrey200,
            border: `1px solid ${theme.colors.astraGrey200}`,
            color: 'white',
          },
        },
      },
      defaultProps: {
        size: 'md',
      },
    },
    Text: {
      baseStyle: {
        fontFamily: 'medium',
        fontSize: 'md',
        lineHeight: 'normal',
      },
      sizes: {
        xxs: {
          fontSize: '10px',
          lineHeight: '14px',
        },
        xs: {
          fontSize: '12px',
          lineHeight: '16px',
        },
        sm: {
          fontSize: '14px',
          lineHeight: '20px',
        },
        md: {
          fontSize: '16px',
          lineHeight: '24px',
        },
        lg: {
          fontSize: '18px',
          lineHeight: '28px',
        },
        xl: {
          fontSize: '20px',
          lineHeight: '30px',
        },
        xxl: {
          fontSize: '24px',
          lineHeight: '36px',
        },
      },
      variants: {
        light: {
          fontFamily: 'light',
        },
        book: {
          fontFamily: 'book',
        },
        medium: {
          fontFamily: 'medium',
        },
        heavy: {
          fontFamily: 'heavy',
        },
        almostWhite: {
          color: palette.almostWhite,
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'medium',
      },
    },
    Badge: {
      baseStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',
        fontWeight: '600',
        borderRadius: 'full',
      },
      sizes: {
        xs: {
          minH: '20px',
          minW: '60px',
          fontSize: '10px',
          px: 2,
          py: 0.75,
        },
        sm: {
          minH: '22px',
          minW: '70px',
          fontSize: '11px',
          px: 2.5,
          py: 1,
        },
        md: {
          minH: '24px',
          minW: '80px',
          fontSize: '12px',
          px: 3,
          py: 1.25,
        },
        lg: {
          minH: '26px',
          minW: '90px',
          fontSize: '13px',
          px: 3.5,
          py: 1.5,
        },
      },
      variants: {
        bordered: {
          border: '1px solid currentColor',
        },
      },
      defaultProps: {
        size: 'xs',
        variant: 'subtle',
      },
    },
  },
})

export default theme
