import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  unwrapResult,
} from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import apiRequest from '../../utils/api'

const addressesAdapter = createEntityAdapter()

export const getAddresses = createAsyncThunk(
  'addresses/getAll',
  async (empty, { dispatch }) => {
    const { addresses = [] } = await dispatch(
      apiRequest({
        method: 'get',
        route: 'addresses',
      }),
    ).then(unwrapResult)

    return addresses
  },
)

export const removeAddress = createAsyncThunk(
  'addresses/removeAddress',
  async ({ id }, { dispatch }) => {
    try {
      await dispatch(
        apiRequest({
          method: 'delete',
          route: `addresses/${id}`,
        }),
      ).then(unwrapResult)

      toast.success('Your address has been removed.')
      dispatch(getAddresses())
    } catch (error) {
      if (!error.description) toast.error(error.message || error.name)

      throw error
    }
  },
)

const addressesSlice = createSlice({
  name: 'addresses',
  initialState: addressesAdapter.getInitialState({
    addresses: [],
    defaultAddress: {},
    loading: true,
    isRemoving: false,
  }),
  extraReducers: builder => {
    builder
      .addCase(removeAddress.pending, state => {
        state.isRemoving = true
      })
      .addCase(removeAddress.fulfilled, state => {
        state.isRemoving = false
      })
      .addCase(removeAddress.rejected, state => {
        state.isRemoving = false
      })
      .addCase(getAddresses.pending, state => {
        state.loading = true
      })
      .addCase(getAddresses.fulfilled, (state, action) => {
        if (action.payload) {
          addressesAdapter.setAll(state, action.payload)
          // Set a "default Address" if the User has at least one Address in the system
          state.defaultAddress = action.payload?.[0] || {}
        }
        state.loading = false
      })
      .addCase(getAddresses.rejected, state => {
        state.loading = false
      })
  },
})

export const addressesSelectors = addressesAdapter.getSelectors(
  state => state.addresses,
)
export const selectDefaultAddress = state => state.addresses.defaultAddress
export const selectAddressesLoading = state => state.addresses.loading
export const selectIsRemovingAddress = state => state.addresses.isRemoving

export default addressesSlice.reducer
