import { Flex, FormControl, FormLabel, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'

import MaskedInput from 'src/atoms/MaskedInput'
import { clearPhoneNumberFormatting } from 'src/utils/format'

const validatePhoneNumber = (phoneNumber: string): string | undefined => {
  const clearedPhoneNumber = clearPhoneNumberFormatting(phoneNumber)

  return clearedPhoneNumber.length < 11 ? 'required' : undefined
}

interface PhoneNumberFieldProps {
  control: Control<any, any>
  defaultValue: string
  error?: FieldError
  id: string
  isOptional?: boolean
  name?: string
  placeholder: string
  readOnly?: boolean
  label?: string
}

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
  control,
  defaultValue,
  error,
  id,
  isOptional,
  name = id,
  placeholder,
  readOnly,
  label = 'Phone Number',
}) => (
  <FormControl>
    <Flex alignItems='center' mb={1}>
      <Text
        as={FormLabel}
        htmlFor={id}
        mb={0}
        mr={0}
        size='sm'
        color='gray.600'
      >
        {label}
        {!isOptional && (
          <Text size='sm' as='span' color='red.500'>
            {' *'}
          </Text>
        )}
      </Text>
    </Flex>
    <Stack gap={2}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || ''}
        rules={
          isOptional
            ? { required: false }
            : {
                validate: {
                  phoneRequired: validatePhoneNumber,
                },
              }
        }
        render={({ field: { onChange, onBlur, value } }) => (
          <MaskedInput
            bordercolor={error ? 'error' : 'border'}
            format='+1 (###) ###-####'
            id={id}
            mask=' '
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                const form = e.currentTarget.closest('form')
                if (form) {
                  form.requestSubmit()
                }
              }
            }}
            placeholder={placeholder}
            readOnly={readOnly}
            type='tel'
            value={value}
            autoFocus
            getInputRef={(el: HTMLInputElement) => {
              if (el?.value && el.value !== value) {
                onChange(el.value)
              }
            }}
          />
        )}
      />
      {error && (
        <Text color='red.500' size='xs'>
          {error.message}
        </Text>
      )}
    </Stack>
  </FormControl>
)

export default PhoneNumberField
