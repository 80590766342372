import { FormEvent, useEffect, useState } from 'react'
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormReturn,
  ValidationMode,
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { selectUser } from 'src/features/auth/authSlice'
import {
  BusinessFlowStepIndex,
  determineBeneficialOwners,
  selectBusinessController,
  setBusinessFlowStep,
  setBusinessController,
  selectIsKybDelegated,
  selectBusinessInfo,
} from 'src/features/verify/verifySlice'
import {
  dateOfBirthValidation,
  emailValidation,
  useFormHandler,
  zipCodeValidation,
} from 'src/hooks/useFormHandler'
import { useAppDispatch } from 'src/redux/store'
import { convertDateFromISO } from 'src/utils/format'
import { BusinessType } from 'src/features/verify/types'
import { User } from 'src/features/auth/types'

export enum BusinessControllerFormKeys {
  ADDRESS_1 = 'address1',
  ADDRESS_2 = 'address2',
  CITY = 'city',
  DATE_OF_BIRTH = 'dateOfBirth',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  SSN = 'ssn',
  STATE = 'state',
  TITLE = 'title',
  ZIP = 'zip',
}

export interface BusinessControllerFormSchema {
  [BusinessControllerFormKeys.ADDRESS_1]: string
  [BusinessControllerFormKeys.ADDRESS_2]?: string
  [BusinessControllerFormKeys.CITY]: string
  [BusinessControllerFormKeys.DATE_OF_BIRTH]?: string
  [BusinessControllerFormKeys.EMAIL]: string
  [BusinessControllerFormKeys.FIRST_NAME]: string
  [BusinessControllerFormKeys.LAST_NAME]: string
  [BusinessControllerFormKeys.SSN]?: string
  [BusinessControllerFormKeys.STATE]: string
  [BusinessControllerFormKeys.TITLE]: string
  [BusinessControllerFormKeys.ZIP]: string
}

const requiredFields: Record<keyof BusinessControllerFormSchema, boolean> = {
  [BusinessControllerFormKeys.ADDRESS_1]: true,
  [BusinessControllerFormKeys.ADDRESS_2]: false,
  [BusinessControllerFormKeys.CITY]: true,
  [BusinessControllerFormKeys.DATE_OF_BIRTH]: false,
  [BusinessControllerFormKeys.EMAIL]: true,
  [BusinessControllerFormKeys.FIRST_NAME]: true,
  [BusinessControllerFormKeys.LAST_NAME]: true,
  [BusinessControllerFormKeys.SSN]: false,
  [BusinessControllerFormKeys.STATE]: true,
  [BusinessControllerFormKeys.TITLE]: true,
  [BusinessControllerFormKeys.ZIP]: true,
}

const businessControllerFormDefaultValues: BusinessControllerFormSchema = {
  [BusinessControllerFormKeys.ADDRESS_1]: '',
  [BusinessControllerFormKeys.ADDRESS_2]: '',
  [BusinessControllerFormKeys.CITY]: '',
  [BusinessControllerFormKeys.DATE_OF_BIRTH]: '',
  [BusinessControllerFormKeys.EMAIL]: '',
  [BusinessControllerFormKeys.FIRST_NAME]: '',
  [BusinessControllerFormKeys.LAST_NAME]: '',
  [BusinessControllerFormKeys.SSN]: '',
  [BusinessControllerFormKeys.STATE]: '',
  [BusinessControllerFormKeys.TITLE]: '',
  [BusinessControllerFormKeys.ZIP]: '',
}

const createFormConfig = ({ isKybDelegated }: { isKybDelegated: boolean }) => {
  return {
    fieldVisibility: {
      [BusinessControllerFormKeys.ADDRESS_1]: true,
      [BusinessControllerFormKeys.ADDRESS_2]: true,
      [BusinessControllerFormKeys.CITY]: true,
      [BusinessControllerFormKeys.DATE_OF_BIRTH]: isKybDelegated ? false : true,
      [BusinessControllerFormKeys.EMAIL]: true,
      [BusinessControllerFormKeys.FIRST_NAME]: true,
      [BusinessControllerFormKeys.LAST_NAME]: true,
      [BusinessControllerFormKeys.SSN]: isKybDelegated ? false : true,
      [BusinessControllerFormKeys.STATE]: true,
      [BusinessControllerFormKeys.TITLE]: true,
      [BusinessControllerFormKeys.ZIP]: true,
    },
    requiredFields: {
      [BusinessControllerFormKeys.ADDRESS_1]: true,
      [BusinessControllerFormKeys.ADDRESS_2]: false,
      [BusinessControllerFormKeys.CITY]: true,
      [BusinessControllerFormKeys.DATE_OF_BIRTH]: isKybDelegated ? false : true,
      [BusinessControllerFormKeys.EMAIL]: true,
      [BusinessControllerFormKeys.FIRST_NAME]: true,
      [BusinessControllerFormKeys.LAST_NAME]: true,
      [BusinessControllerFormKeys.SSN]: isKybDelegated ? false : true,
      [BusinessControllerFormKeys.STATE]: true,
      [BusinessControllerFormKeys.TITLE]: true,
      [BusinessControllerFormKeys.ZIP]: true,
    },
  }
}

const createValidationSchema = ({
  user,
  isKybDelegated,
}: {
  user: User
  isKybDelegated: boolean
}): Yup.SchemaOf<BusinessControllerFormSchema> => {
  const schemaFields: Partial<Record<keyof BusinessControllerFormSchema, any>> =
    {
      [BusinessControllerFormKeys.ADDRESS_1]: Yup.string().required(),
      [BusinessControllerFormKeys.ADDRESS_2]: Yup.string(),
      [BusinessControllerFormKeys.CITY]: Yup.string().required(),
      [BusinessControllerFormKeys.FIRST_NAME]: Yup.string().required(),
      [BusinessControllerFormKeys.LAST_NAME]: Yup.string().required(),
      [BusinessControllerFormKeys.STATE]: Yup.string().required(),
      [BusinessControllerFormKeys.TITLE]: Yup.string().required(),
      [BusinessControllerFormKeys.ZIP]: zipCodeValidation,
    }

  if (!isKybDelegated) {
    schemaFields[BusinessControllerFormKeys.DATE_OF_BIRTH] =
      dateOfBirthValidation
    schemaFields[BusinessControllerFormKeys.EMAIL] = emailValidation
    schemaFields[BusinessControllerFormKeys.SSN] = Yup.string()
      .required()
      .when((ssn: string, schema: any) => {
        return schema.test(
          'test SSN length',
          `The ${
            user.status === 'retry' ? 'full' : 'last four digits of the'
          } Social Security Number is required.`,
          (text: string) => {
            const length = text.trim().replace(/-/g, '').length
            if (user.status === 'retry') {
              if (length < 9) {
                return false
              }
            } else {
              if (length < 4) {
                return false
              }
            }
            return true
          },
        )
      })
  }

  return Yup.object().shape(schemaFields as any)
}

const dontClearErrorsOnSuccess = false

// This option allows you to configure the validation strategy before a user submits the form (onSubmit event).
const formValidationMode: keyof ValidationMode = 'onChange'

/**
 * This hook is used to handle the process of the user filling out the `BusinessController` form:
 *
 * * sets initial value of the form field
 * * handles submission of the form, this can include a POST request for the final form submission
 * * exports the `formHandler` (the form API, submit, event handling, etc.) and the `isSubmitDisabled` value
 *   which is used to determine whether the submit button is active.
 * */
export default function useBusinessControllerForm(): BusinessControllerFormReturnType {
  const dispatch = useAppDispatch()

  const businessController = useSelector(selectBusinessController)
  const user = useSelector(selectUser)
  const isKybDelegated = useSelector(selectIsKybDelegated)
  const { businessType } = useSelector(selectBusinessInfo)

  const [controllerTitle, setControllerTitle] = useState<
    'Business Controller' | 'Director'
  >('Business Controller')

  useEffect(() => {
    if (businessType === BusinessType.NONPROFIT) {
      setControllerTitle('Director')
    } else {
      setControllerTitle('Business Controller')
    }
  }, [businessType])

  const selectedFormConfig = createFormConfig({
    isKybDelegated: isKybDelegated,
  })
  const validationSchema = createValidationSchema({
    user,
    isKybDelegated: isKybDelegated,
  }) as any

  // we set `defaultValues` somewhat asynchronously in the `useEffect` hook below
  // once these are set, another `useEffect` hook in `useFormHandler` hook will use
  // `reset` to reset the form anytime the default values change
  // defaultValues are needed in order to properly handle validation issues
  const [defaultValues, setDefaultValues] =
    useState<BusinessControllerFormSchema>({
      ...businessControllerFormDefaultValues,
    })

  const isNewForm = true

  // SET INITIAL/DEFAULT FORM INPUT VALUES
  useEffect(() => {
    if (businessController) {
      const defaultDOB = businessController.dateOfBirth
        ? convertDateFromISO(businessController.dateOfBirth)
        : ''

      setDefaultValues({
        ...defaultValues,
        ...businessController,
        [BusinessControllerFormKeys.DATE_OF_BIRTH]: defaultDOB,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessController])

  const onSubmit = (data: BusinessControllerFormSchema) => {
    dispatch(setBusinessController(data))

    dispatch(determineBeneficialOwners())
  }

  const onEdit = () => {
    dispatch(setBusinessFlowStep(BusinessFlowStepIndex.CONTROLLER))
  }

  const formHandler = useFormHandler<BusinessControllerFormSchema>(
    async data => {
      return onSubmit(data)
    },
    defaultValues,
    validationSchema,
    dontClearErrorsOnSuccess,
    formValidationMode,
  )

  const { isValid, errors } = formHandler.form.formState

  return {
    ...formHandler,
    defaultValues: formHandler.form.formState
      .defaultValues as BusinessControllerFormSchema,
    errors,
    fieldVisibility: selectedFormConfig.fieldVisibility,
    isKybDelegated: isKybDelegated,
    isNewForm,
    isSubmitDisabled: !isValid,
    onEdit,
    requiredFields,
    controllerTitle,
  }
}

export type BusinessControllerFormReturnType = {
  defaultValues: BusinessControllerFormSchema
  errors: FieldErrorsImpl<DeepRequired<BusinessControllerFormSchema>>
  fieldVisibility: Partial<Record<keyof BusinessControllerFormSchema, boolean>>
  form: UseFormReturn<BusinessControllerFormSchema, any>
  isKybDelegated: boolean
  isNewForm: boolean
  isSubmitDisabled: boolean
  onEdit: () => void
  onSubmit: (e?: FormEvent<Element> | undefined) => Promise<void>
  requiredFields: Record<string, boolean>
  controllerTitle: 'Business Controller' | 'Director'
}
