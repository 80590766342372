import React from 'react'
import { useSelector } from 'react-redux'
import { Composition } from 'atomic-layout'

import { Button, Container, Text } from 'src/atoms'
import { selectBusinessInfo } from 'src/features/verify/verifySlice'
import useBusinessAddressForm from 'src/features/verify/business/BusinessAddress/useBusinessAddressForm'
import AddressForm from 'src/features/verify/AddressForm'

const areasMobile = `
  title
  subtitle
  form
`

const completedAreasMobile = `
  address1 edit
  address2 edit
  cityStateAndZip edit
`
interface VerifyBusinessAddressProps {
  isReadOnly: boolean
}

const BusinessAddress: React.FC<VerifyBusinessAddressProps> = ({
  isReadOnly,
}) => {
  const { defaultValues, errors, form, isSubmitDisabled, onEdit, onSubmit } =
    useBusinessAddressForm()

  const { address1, address2, city, state, zip } =
    useSelector(selectBusinessInfo)

  if (isReadOnly) {
    return (
      <Composition
        areas={completedAreasMobile}
        as={Container}
        gap={3}
        alignItems='center'
        justifyContent='space-between'
      >
        {Areas => (
          <>
            <Areas.Address1>
              <Text fontFamily='medium' color='textSubtle'>
                {address1}
              </Text>
            </Areas.Address1>
            {address2 && (
              <Areas.Address2>
                <Text fontFamily='medium' color='textSubtle'>
                  {address2}
                </Text>
              </Areas.Address2>
            )}
            <Areas.CityStateAndZip>
              <Composition areas='city state zip' gap={10}>
                {SubAreas => (
                  <>
                    <SubAreas.City>
                      <Text fontFamily='medium' color='textSubtle'>
                        {city}
                      </Text>
                    </SubAreas.City>
                    <SubAreas.State>
                      <Text fontFamily='medium' color='textSubtle'>
                        {state}
                      </Text>
                    </SubAreas.State>
                    <SubAreas.Zip>
                      <Text fontFamily='medium' color='textSubtle'>
                        {zip}
                      </Text>
                    </SubAreas.Zip>
                  </>
                )}
              </Composition>
            </Areas.CityStateAndZip>
            <Areas.Edit>
              <Text
                onClick={onEdit}
                color='primary'
                cursor='pointer'
                fontFamily='medium'
              >
                Edit
              </Text>
            </Areas.Edit>
          </>
        )}
      </Composition>
    )
  }

  return (
    <Composition areas={areasMobile} as={Container}>
      {Areas => (
        <>
          <Areas.Title marginBottom={3}>
            <Text fontFamily='heavy' size='large'>
              Verify Business Address
            </Text>
          </Areas.Title>
          <Areas.Subtitle marginBottom={15}>
            <Text fontFamily='medium' size='small' color='textSubtle'>
              Enter your business physical address.
            </Text>
          </Areas.Subtitle>
          <Areas.Form>
            <form onSubmit={onSubmit}>
              <AddressForm
                control={form.control}
                defaultValues={defaultValues}
                errors={errors}
                register={form.register}
              />
              <Button
                type='submit'
                disabled={isSubmitDisabled}
                fullWidth
                style={{ marginTop: 12 }}
              >
                Continue
              </Button>
            </form>
          </Areas.Form>
        </>
      )}
    </Composition>
  )
}

export default BusinessAddress
