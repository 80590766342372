import { FormEvent, useEffect, useState } from 'react'
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormReturn,
  ValidationMode,
} from 'react-hook-form'
import { useSelector } from 'react-redux'

import * as Yup from 'yup'
import { useFormHandler, zipCodeValidation } from 'src/hooks/useFormHandler'
import { useAppDispatch } from 'src/redux/store'
import {
  BusinessFlowStepIndex,
  selectBusinessInfo,
  setBusinessFlowStep,
  setBusinessAddress,
} from 'src/features/verify/verifySlice'
import { Address } from 'src/features/verify/types'

export enum BusinessAddressFormKeys {
  ADDRESS_1 = 'address1',
  ADDRESS_2 = 'address2',
  CITY = 'city',
  STATE = 'state',
  ZIP = 'zip',
}

export type BusinessAddressFormSchema = Address

const requiredFields: Record<keyof BusinessAddressFormSchema, boolean> = {
  [BusinessAddressFormKeys.ADDRESS_1]: true,
  [BusinessAddressFormKeys.ADDRESS_2]: false,
  [BusinessAddressFormKeys.CITY]: true,
  [BusinessAddressFormKeys.STATE]: true,
  [BusinessAddressFormKeys.ZIP]: true,
}

const businessAddressFormDefaultValues: BusinessAddressFormSchema = {
  [BusinessAddressFormKeys.ADDRESS_1]: '',
  [BusinessAddressFormKeys.ADDRESS_2]: '',
  [BusinessAddressFormKeys.CITY]: '',
  [BusinessAddressFormKeys.STATE]: '',
  [BusinessAddressFormKeys.ZIP]: '',
}

const validationSchema: Yup.SchemaOf<BusinessAddressFormSchema> =
  Yup.object().shape({
    [BusinessAddressFormKeys.ADDRESS_1]: Yup.string().required(),
    [BusinessAddressFormKeys.ADDRESS_2]: Yup.string(),
    [BusinessAddressFormKeys.CITY]: Yup.string().required(),
    [BusinessAddressFormKeys.STATE]: Yup.string().required(),
    [BusinessAddressFormKeys.ZIP]: zipCodeValidation,
  })

const dontClearErrorsOnSuccess = false

// This option allows you to configure the validation strategy before a user submits the form (onSubmit event).
const formValidationMode: keyof ValidationMode = 'onChange'

/**
 * This hook is used to handle the process of the user filling out the `BusinessAddress` form:
 *
 * * sets initial value of the form field
 * * handles submission of the form, this can include a POST request for the final form submission
 * * exports the `formHandler` (the form API, submit, event handling, etc.) and the `isSubmitDisabled` value
 *   which is used to determine whether the submit button is active.
 * */
export default function useBusinessAddressForm(): BusinessAddressFormReturnType {
  const dispatch = useAppDispatch()

  const businessInfo = useSelector(selectBusinessInfo)

  // we set `defaultValues` somewhat asynchronously in the `useEffect` hook below
  // once these are set, another `useEffect` hook in `useFormHandler` hook will use
  // `reset` to reset the form anytime the default values change
  // defaultValues are needed in order to properly handle validation issues
  const [defaultValues, setDefaultValues] = useState<BusinessAddressFormSchema>(
    {
      ...businessAddressFormDefaultValues,
    },
  )

  // TODO(worstestes - 12/3/23): determine how we designate a 'new' form, meaning the user hasn't completed this form before
  const isNewForm = true

  // SET INITIAL/DEFAULT FORM INPUT VALUES
  useEffect(() => {
    if (businessInfo) {
      setDefaultValues({
        ...defaultValues,
        [BusinessAddressFormKeys.ADDRESS_1]:
          businessInfo[BusinessAddressFormKeys.ADDRESS_1],
        [BusinessAddressFormKeys.ADDRESS_2]:
          businessInfo[BusinessAddressFormKeys.ADDRESS_2],
        [BusinessAddressFormKeys.CITY]:
          businessInfo[BusinessAddressFormKeys.CITY],
        [BusinessAddressFormKeys.STATE]:
          businessInfo[BusinessAddressFormKeys.STATE],
        [BusinessAddressFormKeys.ZIP]:
          businessInfo[BusinessAddressFormKeys.ZIP],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessInfo])

  const onSubmit = (data: BusinessAddressFormSchema) => {
    dispatch(setBusinessAddress(data))
    dispatch(setBusinessFlowStep(BusinessFlowStepIndex.ADMIN))
  }

  const onEdit = () => {
    dispatch(setBusinessFlowStep(BusinessFlowStepIndex.ADDRESS))
  }

  const formHandler = useFormHandler<BusinessAddressFormSchema>(
    async data => {
      return onSubmit(data)
    },
    defaultValues,
    validationSchema,
    dontClearErrorsOnSuccess,
    formValidationMode,
  )

  const { isValid, errors } = formHandler.form.formState

  return {
    ...formHandler,
    defaultValues: formHandler.form.formState
      .defaultValues as BusinessAddressFormSchema,
    errors,
    isNewForm,
    isSubmitDisabled: !isValid,
    onEdit,
    requiredFields,
  }
}

export type BusinessAddressFormReturnType = {
  defaultValues: BusinessAddressFormSchema
  errors: FieldErrorsImpl<DeepRequired<BusinessAddressFormSchema>>
  form: UseFormReturn<BusinessAddressFormSchema, any>
  isNewForm: boolean
  isSubmitDisabled: boolean
  onEdit: () => void
  onSubmit: (e?: FormEvent<Element> | undefined) => Promise<void>
  requiredFields: Record<string, boolean>
}
