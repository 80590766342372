import { FirebaseError } from 'firebase/app'
import { Environment } from 'src/features/auth/types'
import { toastError } from 'src/payment-links/utils/toast'

enum FirebaseAuthErrorType {
  INVALID_PHONE_NUMBER = 'auth/invalid-phone-number',
  INVALID_VERIFICATION_CODE = 'auth/invalid-verification-code',
  MISSING_PHONE_NUMBER = 'auth/missing-phone-number',
  QUOTA_EXCEEDED = 'auth/quota-exceeded',
  SESSION_EXPIRED = 'auth/session-expired',
  TOO_MANY_REQUESTS = 'auth/too-many-requests',
  UNKNOWN_ERROR = 'auth/unknown-error',
}

/**
 * A function that logs the auth error and triggers an error toast for user notification
 * @param error - a firebase generated error
 */
export const handleAuthError = (error: FirebaseError): void => {
  if (process.env.REACT_APP_RELEASE_STAGE === Environment.DEVELOPMENT) {
    console.error(error)
  }

  // @TODO(worstestes): currently only supports auth errors
  switch (error.code) {
    case FirebaseAuthErrorType.INVALID_PHONE_NUMBER:
      toastError('Please enter a valid phone number.')
      break
    case FirebaseAuthErrorType.MISSING_PHONE_NUMBER:
      toastError('Please enter your phone number.')
      break
    case FirebaseAuthErrorType.QUOTA_EXCEEDED:
      toastError(
        'You have exceeded the maximum number of verification attempts. Please try again later.',
      )
      break
    case FirebaseAuthErrorType.SESSION_EXPIRED:
      toastError(
        'Your verification code has expired. Please request a new one.',
      )
      break
    case FirebaseAuthErrorType.INVALID_VERIFICATION_CODE:
      toastError(
        'The verification code entered is incorrect. Please try again.',
      )
      break
    case FirebaseAuthErrorType.TOO_MANY_REQUESTS:
      toastError('Too many attempts. Please try again later.')
      break
    default:
      toastError('An unexpected error occurred. Please try again later.')
      break
  }
}
