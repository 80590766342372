import React from 'react'
import { Control, Controller, FieldError } from 'react-hook-form'
import Select from 'src/atoms/Select'

interface SelectFieldProps {
  control?: Control<any, any>
  id: string
  isOptional?: boolean
  name: string
  defaultValue: string
  options: Array<{ label: string; value: string }>
  disabled?: boolean
  error?: FieldError
  style?: Record<string, string | number>
}

const SelectField = React.forwardRef<HTMLSelectElement, SelectFieldProps>(
  (props, _ref) => {
    const {
      name,
      defaultValue,
      options,
      disabled = false,
      error,
      control,
      isOptional = true,
      style,
    } = props

    return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || '0'}
        rules={{
          required: !isOptional,
        }}
        render={({ field }) => (
          <Select
            bordercolor={error && 'error'}
            {...field}
            disabled={disabled}
            style={style}
          >
            {options.map(option => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
      />
    )
  },
)

SelectField.displayName = 'SelectField'

export default SelectField
