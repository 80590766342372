import React from 'react'
import { ChakraProvider, Box } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter } from 'react-router-dom'
import { chakraTheme } from '../theme'

import Router from './Router'
import { ToastProviderWrapper } from 'src/payment-links/components/Toaster'

const queryClient = new QueryClient()

export const PaymentLinkApp: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={chakraTheme}>
        <BrowserRouter>
          <ToastProviderWrapper>
            <Box
              bg='gray.50'
              minH='100vh'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <Router />
            </Box>
            <ReactQueryDevtools initialIsOpen={false} />
          </ToastProviderWrapper>
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  )
}
