import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  unwrapResult,
} from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { RootState } from '../../redux/reducer'

import apiRequest from '../../utils/api'

const cardsAdapter = createEntityAdapter()

export const getCards = createAsyncThunk(
  'cards/getAll',
  async ({ clientId }: { clientId: string }, { dispatch }) => {
    const { cards = [] } = (await dispatch(
      apiRequest({
        method: 'get',
        route: 'cards',
        ...(clientId && {
          data: {
            oauth_client_id_created_by: clientId,
          },
        }),
      }),
    ).then(unwrapResult)) as { cards: any[] }

    return cards
  },
)

export const removeCard = createAsyncThunk(
  'cards/removeCard',
  async ({ id }: { id: string }, { dispatch, getState }) => {
    try {
      const {
        auth: {
          client: { clientId },
        },
      } = getState() as RootState

      await dispatch(
        apiRequest({
          method: 'delete',
          route: `cards/${id}`,
        }),
      ).then(unwrapResult)

      toast.success('Your debit card has been removed.')
      dispatch(getCards({ clientId }))
    } catch (e) {
      const error = e as any
      if (!error.description) toast.error(error.message || error.name)

      throw error
    }
  },
)

const cardsSlice = createSlice({
  name: 'cards',
  initialState: cardsAdapter.getInitialState({
    loading: false,
    isRemoving: false,
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(removeCard.pending, state => {
      state.isRemoving = true
    })
    builder.addCase(removeCard.fulfilled, state => {
      state.isRemoving = false
    })
    builder.addCase(removeCard.rejected, state => {
      state.isRemoving = false
    })
    builder.addCase(getCards.pending, state => {
      state.loading = true
    })
    builder.addCase(getCards.fulfilled, (state, action) => {
      if (action.payload) {
        cardsAdapter.setAll(state, action.payload)
      }
      state.loading = false
    })
    builder.addCase(getCards.rejected, state => {
      state.loading = false
    })
  },
})

export const cardsSelectors = cardsAdapter.getSelectors(
  (state: RootState) => state.cards,
)
export const selectCardsLoading = (state: RootState): boolean =>
  state.cards.loading
export const selectIsRemovingCard = (state: RootState): boolean =>
  state.cards.isRemoving

export default cardsSlice.reducer
