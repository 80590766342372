import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/redux/store'
import * as queryString from 'query-string'
import { useLocation } from '@reach/router'

import Authorize from './Authorize'
import LoginModule from './LoginModule'
import SignUpModule from './SignUpModule'
import {
  astraAuth,
  selectClient,
  selectCustomFirebaseToken,
  selectHasAuthorized,
  selectIsLoggingInWithCustomToken,
  selectShowSignUpFields,
  setIsLoggingInWithCustomToken,
  providerLogin,
  attemptSignInWithCustomToken,
  setUserSignupData,
} from './authSlice'
import { AuthFlow } from 'src/features/auth/types'
import useAuthorize from 'src/features/auth/hooks/useAuthorize'

const Login: React.FC = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const {
    business: businessQueryString,
    business_profile_id,
    bypass_connect,
    client_id,
    return_to,
    session_token,
    user_id,
    user_intent_id,
  } = queryString.parse(location.search)

  const business: boolean = businessQueryString ? true : false
  const businessProfileId: string = business_profile_id as string
  const bypassConnect: string = bypass_connect as string
  const clientId: string = client_id as string
  const returnTo: string = return_to as string
  const sessionToken: string = session_token as string
  const userId: string = user_id as string
  const userIntentId: string = user_intent_id as string

  const clientName = useSelector(selectClient).name
  const customFirebaseToken = useSelector(selectCustomFirebaseToken)
  const hasAuthorized = useSelector(selectHasAuthorized)
  const isLoggingInWithCustomToken = useSelector(
    selectIsLoggingInWithCustomToken,
  )
  const showSignUpFields = useSelector(selectShowSignUpFields)

  const { isAuthorizeRequired } = useAuthorize()

  const submitSignUp = ({
    firstName,
    lastName,
    email,
  }: {
    firstName: string
    lastName: string
    email: string
  }) => {
    dispatch(setUserSignupData({ firstName, lastName, email }))

    dispatch(
      astraAuth({
        authFlow: AuthFlow.SIGN_UP,
        business,
        businessProfileId,
        bypassConnect,
        clientId,
        returnTo,
        userId,
        userIntentId,
      }),
    )
  }

  useEffect(() => {
    if (sessionToken) {
      if (hasAuthorized && isLoggingInWithCustomToken) {
        if (customFirebaseToken) {
          dispatch(attemptSignInWithCustomToken({ token: customFirebaseToken }))
        } else {
          dispatch(
            providerLogin({
              businessProfileId,
              clientId,
              sessionToken,
              userId,
              userIntentId,
            }),
          )
        }
      }
    } else {
      dispatch(setIsLoggingInWithCustomToken(false))
    }
  }, [
    businessProfileId,
    clientId,
    customFirebaseToken,
    dispatch,
    hasAuthorized,
    isLoggingInWithCustomToken,
    sessionToken,
    userId,
    userIntentId,
  ])

  if (clientId && !clientName) return null

  if (isAuthorizeRequired) {
    return <Authorize />
  }

  if (showSignUpFields) {
    return <SignUpModule onSubmit={submitSignUp} />
  }

  return <LoginModule />
}

export default Login
