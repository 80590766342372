import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import {
  createTransform,
  persistReducer,
  persistStore,
  PersistConfig,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

import storage from 'redux-persist/lib/storage'
import {
  AuthSliceState,
  initialState as authSliceInitialState,
} from 'src/features/auth/authSlice'

import appReducer, { RootState } from './reducer'

const selectedAuthSliceTransform = createTransform(
  (inboundState: AuthSliceState) => {
    return {
      client: inboundState.client,
    }
  },

  outboundState => {
    return { ...authSliceInitialState, ...outboundState }
  },

  { whitelist: ['auth'] },
)

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  version: 2,
  storage,
  whitelist: ['auth'],
  transforms: [selectedAuthSliceTransform],
}

const persistedReducer = persistReducer(persistConfig, appReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = (): ThunkDispatch<RootState, void, Action> =>
  useDispatch<AppDispatch>()

const persistor = persistStore(store)

export { store, persistor }
