import { toaster } from '../components/Toaster'

interface ToastOptions {
  title?: string
  description?: string
  type?: 'info' | 'warning' | 'success' | 'error' | 'loading'
  duration?: number | null
  isClosable?: boolean
  onClose?: () => void
  action?: {
    label: string
    onClick: () => void
  }
}

export const showToast = (options: ToastOptions) => {
  toaster.create({
    ...options,
    status: options.type,
    isClosable: options.isClosable ?? true,
  })
}

export const toastSuccess = (title: string, description?: string) => {
  showToast({ title, description, type: 'success' })
}

export const toastError = (title: string, description?: string) => {
  showToast({ title, description, type: 'error' })
}

export const toastInfo = (title: string, description?: string) => {
  showToast({ title, description, type: 'info' })
}

export const toastWarning = (title: string, description?: string) => {
  showToast({ title, description, type: 'warning' })
}

export const toastLoading = (title: string, description?: string) => {
  showToast({
    title,
    description,
    type: 'loading',
    duration: null,
  })
}
