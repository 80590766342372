import { combineReducers } from '@reduxjs/toolkit'

import accounts from '../features/accounts/accountsSlice'
import addresses from '../features/accounts/addressesSlice'
import auth from '../features/auth/authSlice'
import cards from '../features/accounts/cardsSlice'
import institutions from '../features/accounts/institutionsSlice'
import clients from '../features/clients/clientsSlice'
import routines from '../features/routines/routinesSlice'
import sdkMessaging from '../features/sdkMessaging/sdkMessagingSlice'
import transfers from '../features/transfers/transfersSlice'
import verify from '../features/verify/verifySlice'

const rootReducer = combineReducers({
  accounts,
  addresses,
  auth,
  cards,
  institutions,
  clients,
  routines,
  sdkMessaging,
  transfers,
  verify,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
