import { FormEvent, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  DeepPartial,
  DefaultValues,
  FieldValues,
  useForm,
  UseFormReturn,
  ValidationMode,
} from 'react-hook-form'

import * as Yup from 'yup'
import useDeepEffect from './useDeepEffect'
import { DateTime } from 'luxon'
import stateOptions from 'src/utils/stateOptions'

// Default Yup error message for required fields
Yup.setLocale({
  mixed: {
    default: 'This field has an invalid value.',
    required: 'This field is required.',
  },
})

export type FormHandler<T extends FieldValues> = {
  form: UseFormReturn<T, any>
  error?: Error
  onSubmit: (e?: FormEvent) => Promise<void>
}

export function useFormHandler<T extends FieldValues = Record<string, unknown>>(
  handleSubmit: (values: T) => void,
  defaultValues: DeepPartial<T>,
  schema: Yup.AnyObjectSchema,
  dontClearErrorsOnSuccess = false,
  mode: keyof ValidationMode,
  validationContext?: {
    isFormReady?: boolean
    isLoading?: boolean
    context?: Record<string, any>
  },
): FormHandler<T> {
  const form = useForm<T>({
    defaultValues: defaultValues as DefaultValues<T> | undefined,
    resolver: schema
      ? yupResolver(schema, { context: validationContext?.context })
      : undefined,
    mode,
    reValidateMode: 'onChange',
  })
  const [error, setError] = useState<Error | undefined>()

  const onSubmit = form.handleSubmit(async values => {
    try {
      await handleSubmit(values)

      if (!dontClearErrorsOnSuccess) {
        form.clearErrors()
        setError(undefined)
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e)
      }
    }
  })

  useDeepEffect(() => {
    if (!validationContext?.isLoading) {
      form.reset(defaultValues as DefaultValues<T>)
    }
  }, [defaultValues, validationContext?.isLoading])

  return {
    onSubmit,
    form,
    error,
  }
}

export const dateOfBirthValidation = Yup.string()
  .required()
  .when((dob: any, schema: any) => {
    return schema.test(
      'test DOB validity',
      'Please enter a valid date of birth.',
      (input: string) => {
        if (input.length < 10) {
          return false
        }

        const regexDateFormatMatch = input.match(
          /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/,
        )

        if (!regexDateFormatMatch) {
          return false
        }

        try {
          const [month, day, year] = input.split('/')
          const fullYear = `${year.length === 2 ? '20' : ''}${year}`

          const formattedDate = DateTime.fromObject({
            year: Number(fullYear),
            month: Number(month),
            day: Number(day),
          })

          const dateTimeToday = DateTime.fromJSDate(new Date())

          const differenceInYears = dateTimeToday.diff(
            formattedDate,
            'years',
          ).years

          const isInvalidDate = isNaN(differenceInYears)

          if (isInvalidDate) {
            return false
          }

          const impossibleAgeMaxRange = differenceInYears >= 125
          const impossibleAgeMinRange = differenceInYears <= 0

          if (impossibleAgeMaxRange || impossibleAgeMinRange) {
            return false
          }
        } catch {
          return false
        }

        return true
      },
    )
  })

export const zipCodeValidation = Yup.string()
  .required()
  .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Please enter a valid zip code.')

export const emailValidation = Yup.string()
  .email('Please enter a valid email address. (e.g. example@example.org)')
  .required()

const stateAbbreviations = stateOptions.map(option => option.value)
export const stateValidation = Yup.string().oneOf(stateAbbreviations)

export const phoneValiation = Yup.string()
  .required()
  .matches(
    /^\+\d{1,2}\s\(\d{3}\)\s\d{3}-\d{4}$/,
    'Please enter a valid phone number.',
  )
