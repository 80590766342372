import React, { useEffect } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import * as queryString from 'query-string'
import { ThemeProvider } from 'styled-components'
import { Box } from 'atomic-layout'
import { Toaster } from 'react-hot-toast'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'

import Footer from './components/Footer'
import FullScreenSpinner from './components/FullScreenSpinner'
import Header from './components/Header'
import Router from './Router'
import { store, persistor } from './redux/store'
import {
  astraAuth,
  firebaseTokenNotFound,
  getClientPublic,
  logout,
  selectIsLoggingIn,
  setFirebaseToken,
  setPhoneNumber,
} from './features/auth/authSlice'
import { addSdkListener } from './features/sdkMessaging/sdkMessagingSlice'
import {
  hideRecaptcha,
  onAuthStateChanged,
  resetRecaptcha,
} from './utils/firebase'
import { astraTheme } from './styles/theme'
import { AuthFlow } from 'src/features/auth/types'
import InactivityModal from 'src/features/auth/InactivityModal'
import { PersistGate } from 'redux-persist/integration/react'
import { PaymentLinkApp } from './payment-links/App'

const primaryColor = {
  primary: {
    500: astraTheme.colors.primary,
  },
}
const chakraTheme = extendTheme({ colors: primaryColor })

const AstraWebApp: React.FC = () => {
  const dispatch = useDispatch()
  const isLoggingIn = useSelector(selectIsLoggingIn)

  useEffect(() => {
    const auth = onAuthStateChanged(async firebaseUser => {
      const {
        business: businessQueryString,
        business_profile_id,
        bypass_connect,
        client_id,
        debit_direct: debitDirect,
        redirect_uri: redirectUri,
        response_type: responseType,
        return_to,
        state,
        user_id,
        user_intent_id,
        phone,
      } = queryString.parse(window.location.search)

      const business = businessQueryString ? true : false
      const businessProfileId = business_profile_id as string
      const bypassConnect = bypass_connect as string
      const clientId = client_id as string
      const returnTo = return_to as string
      const userId = user_id as string
      const userIntentId = user_intent_id as string

      if (firebaseUser) {
        const { phoneNumber } = firebaseUser

        const firebaseUserPhoneNumber = phoneNumber ? phoneNumber.slice(2) : ''

        // ensure incoming phone number matches current user, else clear user session
        if (phone && phone !== firebaseUserPhoneNumber) {
          return dispatch(logout({ shouldRedirect: false, clearParams: false }))
        }

        let returnToPath = ''
        if (returnTo) {
          returnToPath = returnTo
        } else if (
          clientId &&
          redirectUri &&
          responseType &&
          window.location.pathname === '/login/oauth/authorize'
        ) {
          const oAuthState = state ? `&state=${state}` : ''
          const debitDirectString =
            debitDirect === 'true' ? '&debit_direct=true' : ''
          returnToPath = `/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}${oAuthState}${debitDirectString}`
        }
        const token = await firebaseUser.getIdToken()

        dispatch(setFirebaseToken(token))
        dispatch(setPhoneNumber(phoneNumber))
        dispatch(
          astraAuth({
            authFlow: AuthFlow.LOGIN,
            business,
            businessProfileId,
            bypassConnect,
            clientId,
            returnTo: returnToPath,
            userId,
            userIntentId,
          }),
        )

        hideRecaptcha()

        return
      }

      dispatch(firebaseTokenNotFound())
    })

    return auth
  }, [dispatch])

  useEffect(() => {
    const { client_id, return_to } = queryString.parse(window.location.search)

    const clientId: string = client_id as string
    const returnTo: string = return_to as string

    // it's possible for the clientId to exist within the returnTo param
    // let's check for that so we can pull in the client details
    const returnToSplit = returnTo ? returnTo.split('?')[1] : ''
    const returnToParts = returnToSplit ? queryString.parse(returnToSplit) : {}
    const returnToClientId = (
      returnToParts && returnToParts.client_id ? returnToParts.client_id : ''
    ) as string

    dispatch(getClientPublic(clientId || returnToClientId))
  }, [dispatch])

  useEffect(() => {
    dispatch(addSdkListener())
  }, [dispatch])

  useEffect(() => {
    const renderRecaptcha = async () => {
      await resetRecaptcha()
    }

    renderRecaptcha()
  }, [])

  if (isLoggingIn) return <FullScreenSpinner />

  return (
    <Box
      as='section'
      flex
      flexDirection='column'
      alignItems='center'
      width='100%'
      minHeight='100%'
    >
      <InactivityModal />
      <Header />
      <Router />
      <Footer />
      <Toaster />
    </Box>
  )
}

const App: React.FC = () => {
  // TEMP: check if we're in a payment link flow
  const isPaymentLink = window.location.toString().includes('pay')

  if (isPaymentLink) {
    return <PaymentLinkApp />
  }

  return <AstraWebApp />
}

const AppContainer: React.FC = () => (
  // @ts-ignore
  <Provider store={store}>
    <Box data-testid='app' height='100%'>
      <ChakraProvider theme={chakraTheme}>
        <ThemeProvider theme={astraTheme}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
          <div id='recaptcha-container' />
        </ThemeProvider>
      </ChakraProvider>
    </Box>
  </Provider>
)

export default AppContainer
