import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Composition } from 'atomic-layout'
import { useForm } from 'react-hook-form'

import SelectField from 'src/components/SelectField'
import { Button, Container, Input, Text } from 'src/atoms'
import stateOptions from 'src/utils/stateOptions'
import {
  selectIsSubmitting,
  selectVerifyPersonal,
} from 'src/features/verify/verifySlice'

interface VerifyPersonalAddressProps {
  submit: any
}

const areasMobile = `
  title
  subtitle
  form
`
const formAreasMobile = `
  address1
  address2
  city
  stateAndZip
  continue
`

const VerifyPersonalAddress: React.FC<VerifyPersonalAddressProps> = ({
  submit,
}) => {
  const {
    register,
    formState: { errors, isValid },
    control,
    handleSubmit,
    setValue,
  } = useForm({ mode: 'onChange' })
  const { address1, address2, city, state, zip } =
    useSelector(selectVerifyPersonal)
  const isSubmitting = useSelector(selectIsSubmitting)

  useEffect(() => {
    setValue('state', state)
  }, [state, setValue])

  return (
    <Composition areas={areasMobile} as={Container}>
      {Areas => (
        <>
          <Areas.Title marginBottom={3}>
            <Text fontFamily='heavy' size='large'>
              Verify your Profile
            </Text>
          </Areas.Title>
          <Areas.Subtitle marginBottom={15}>
            <Text fontFamily='medium' size='small' color='textSubtle'>
              Enter your mailing address.
            </Text>
          </Areas.Subtitle>
          <Areas.Form>
            <form onSubmit={handleSubmit(submit)}>
              <Composition areas={formAreasMobile} gap={10}>
                {FormAreas => (
                  <>
                    <FormAreas.Address1>
                      <Input
                        id='address1'
                        type='text'
                        placeholder='Street Address'
                        defaultValue={address1 || ''}
                        bordercolor={errors.address1 && 'error'}
                        {...register('address1', { required: true })}
                      />
                    </FormAreas.Address1>
                    <FormAreas.Address2>
                      <Input
                        id='address2'
                        type='text'
                        placeholder='Apartment, Unit, etc (Optional)'
                        defaultValue={address2 || ''}
                        {...register('address2', { required: false })}
                      />
                    </FormAreas.Address2>
                    <FormAreas.City>
                      <Input
                        id='city'
                        type='text'
                        placeholder='City'
                        defaultValue={city || ''}
                        bordercolor={errors.city && 'error'}
                        {...register('city', { required: true })}
                      />
                    </FormAreas.City>
                    <FormAreas.StateAndZip>
                      <Composition areas='state zip' gap={10}>
                        {SubAreas => (
                          <>
                            <SubAreas.State>
                              <SelectField
                                id='state'
                                control={control}
                                defaultValue={state || '0'}
                                error={errors.state as any}
                                options={stateOptions}
                                {...register('state', {
                                  required: true,
                                  validate: {
                                    stateRequired: value => {
                                      if (value === '0') return 'required'
                                      return undefined
                                    },
                                  },
                                })}
                              />
                            </SubAreas.State>
                            <SubAreas.Zip>
                              <Input
                                id='zip'
                                type='text'
                                maxLength={5}
                                placeholder='Zip Code'
                                defaultValue={zip || ''}
                                bordercolor={errors.zip && 'error'}
                                {...register('zip', {
                                  required: true,
                                  minLength: 5,
                                  maxLength: 5,
                                })}
                              />
                            </SubAreas.Zip>
                          </>
                        )}
                      </Composition>
                    </FormAreas.StateAndZip>
                    <FormAreas.Continue>
                      <Button
                        type='submit'
                        disabled={isSubmitting || !isValid}
                        fullWidth
                      >
                        {isSubmitting ? 'Sending...' : 'Submit'}
                      </Button>
                    </FormAreas.Continue>
                  </>
                )}
              </Composition>
            </form>
          </Areas.Form>
        </>
      )}
    </Composition>
  )
}

export default VerifyPersonalAddress
