import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  unwrapResult,
} from '@reduxjs/toolkit'
import apiRequest from '../../utils/api'

const institutionsAdapter = createEntityAdapter({
  selectId: institution => institution.plaid_token_id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

export const getInstitutions = createAsyncThunk(
  'institutions/getAll',
  async (empty, { dispatch }) => {
    const { plaid_institutions: institutions } = await dispatch(
      apiRequest({
        method: 'get',
        route: 'get_institutions',
      }),
    ).then(unwrapResult)
    return institutions
  },
)

const institutionsSlice = createSlice({
  name: 'institutions',
  initialState: institutionsAdapter.getInitialState({
    loading: false,
  }),
  extraReducers: builder => {
    builder
      .addCase(getInstitutions.pending, state => {
        state.loading = true
      })
      .addCase(getInstitutions.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload) {
          institutionsAdapter.setAll(state, action.payload)
        }
      })
      .addCase(getInstitutions.rejected, state => {
        state.loading = false
      })
  },
})

export const institutionsSelectors = institutionsAdapter.getSelectors(
  state => state.institutions,
)
export const selectInstitutionsLoading = state => state.institutions.loading

export default institutionsSlice.reducer
