import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, WindowLocation } from '@reach/router'
import * as queryString from 'query-string'

import {
  selectIsLoggedIn,
  selectIsLoggingIn,
} from 'src/features/auth/authSlice'
import Authorize from 'src/features/auth/Authorize'
import { leaveBugsnagBreadcrumb } from 'src/utils/logError'
import useAuthorize from 'src/features/auth/hooks/useAuthorize'

interface Props {
  component: typeof React.Component
  location: WindowLocation
  path: string
}

const buildQueryString = (
  params: Record<string, string | boolean | undefined>,
) => {
  const searchParams = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      searchParams.append(key, String(value))
    }
  })
  return searchParams.toString()
}

const normalizeParam = (
  param: string | string[] | null | undefined,
): string | undefined => {
  if (Array.isArray(param)) return param[0]
  return param ?? undefined
}

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  location,
  path,
  ...rest
}) => {
  const queryParams = queryString.parse(location.search)
  const {
    business,
    business_profile_id: businessProfileId,
    bypass_connect: bypassConnect,
    client_id: clientId,
    phone,
    phone_read_only: phoneReadOnly,
    redirect_uri: redirectUri,
    session_token: sessionToken,
    user_id: userId,
    user_intent_id: userIntentId,
  } = queryParams

  leaveBugsnagBreadcrumb({ message: `PrivateRoute: ${location.href}` })

  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isLoggingIn = useSelector(selectIsLoggingIn)

  const { isAuthorizeRequired } = useAuthorize()

  if (isAuthorizeRequired) {
    return <Authorize />
  }

  if (!isLoggedIn && !isLoggingIn) {
    // check if we need to add a returnTo param to navigate the user after auth
    if (clientId && redirectUri) {
      const returnTo = `${path}${location.search}`
      const queryString = buildQueryString({
        client_id: normalizeParam(clientId),
        user_id: normalizeParam(userId),
        user_intent_id: normalizeParam(userIntentId),
        business: business === 'true' ? 'true' : undefined,
        phone: normalizeParam(phone),
        phone_read_only: phoneReadOnly === 'true' ? 'true' : undefined,
        bypass_connect: bypassConnect === 'true' ? 'true' : undefined,
        session_token: normalizeParam(sessionToken),
        business_profile_id: normalizeParam(businessProfileId),
        return_to: returnTo,
      })

      // @ts-ignore
      return <Redirect from='' to={`/login?${queryString}`} noThrow />
    }

    return (
      // @ts-ignore
      <Redirect
        from=''
        to={`/login${phone ? `?phone=${phone}` : ''}`}
        noThrow
      />
    )
  }

  return <Component location={location} {...rest} />
}

export default PrivateRoute
