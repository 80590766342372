import React, { FC, ForwardedRef } from 'react'
import { Composition } from 'atomic-layout'
import { ChangeHandler } from 'react-hook-form'

interface CheckboxFieldProps {
  id: string
  name: string
  onChange: ChangeHandler
  children?: React.ReactNode
}

const areasMobile = `
  checkbox label
`

const CheckboxField: FC<CheckboxFieldProps> = React.forwardRef(
  ({ id, name, onChange, children }, ref: ForwardedRef<HTMLInputElement>) => (
    <Composition areas={areasMobile} gap={10}>
      {Areas => (
        <>
          <Areas.Checkbox>
            <input
              type='checkbox'
              id={id}
              name={name}
              onChange={onChange}
              ref={ref}
            />
          </Areas.Checkbox>
          <Areas.Label>
            <label htmlFor={id}>{children}</label>
          </Areas.Label>
        </>
      )}
    </Composition>
  ),
)

CheckboxField.displayName = 'CheckboxField'

export default CheckboxField
