import React from 'react'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import IonIcon from 'src/atoms/IonIcon'

interface FormNoteProps {
  icon: string
  iconColor?: string
  title: string
  description: string
}

const FormNote: React.FC<FormNoteProps> = ({
  icon,
  iconColor = 'green.500',
  title,
  description,
}) => {
  return (
    <Flex w='full' align='flex-start'>
      <Box pt='2px'>
        <Icon
          as={IonIcon}
          name={icon}
          color={iconColor}
          boxSize={4}
          mr={2}
          size={15}
        />
      </Box>
      <Box textAlign='left' maxW='sm'>
        <Text fontSize='sm' fontWeight='semibold' color='gray.800'>
          {title}
        </Text>
        <Text fontSize='sm' color='gray.500' lineHeight='short' noOfLines={2}>
          {description}
        </Text>
      </Box>
    </Flex>
  )
}

export default FormNote
