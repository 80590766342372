import React from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Auth from 'src/payment-links/features/auth'
import PaymentDetails from './features/payment/PaymentDetails'

const Router: React.FC = () => {
  const location = useLocation()
  const ProtectedPaymentDetails = () => {
    const state = location.state as any

    if (!state?.amount || !state?.senderName) {
      return <Navigate to='/' replace />
    }

    return <PaymentDetails {...state} />
  }

  return (
    <Routes>
      <Route path='/' element={<Auth />} />
      <Route path='/payment-details' element={<ProtectedPaymentDetails />} />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  )
}

export default Router
