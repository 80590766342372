/* global grecaptcha */
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  browserSessionPersistence,
  setPersistence,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  signInWithPhoneNumber as firebaseSignInWithPhoneNumber,
  signOut as firebaseSignOut,
  RecaptchaVerifier,
  ApplicationVerifier,
  ConfirmationResult,
  Unsubscribe,
  UserCredential,
} from 'firebase/auth'
import logError from './logError'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)
setPersistence(auth, browserSessionPersistence)

export const onAuthStateChanged = (
  callback: (...args: any) => void,
): Unsubscribe => {
  return firebaseOnAuthStateChanged(auth, callback)
}

export const signInWithCustomToken = async (
  customToken: string,
): Promise<UserCredential> => {
  return firebaseSignInWithCustomToken(auth, customToken)
}

export const signInWithPhoneNumber = async (
  phoneNumber: string,
  appVerifier: ApplicationVerifier,
): Promise<ConfirmationResult> => {
  return firebaseSignInWithPhoneNumber(auth, phoneNumber, appVerifier)
}

export const signOut = async (): Promise<void> => {
  firebaseSignOut(auth)
}

export const resetRecaptcha = async (): Promise<void> => {
  try {
    if (
      typeof grecaptcha !== 'undefined' &&
      typeof window.recaptchaWidgetId !== 'undefined'
    ) {
      grecaptcha.reset(window.recaptchaWidgetId)
    } else if (
      typeof window.recaptchaVerifier !== 'undefined' &&
      typeof window.recaptchaWidgetId !== 'undefined'
    ) {
      window.recaptchaVerifier.recaptcha.reset(window.recaptchaWidgetId)
    } else if (typeof window.recaptchaVerifier !== 'undefined') {
      window.recaptchaWidgetId = await window.recaptchaVerifier.render()
    } else {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        'recaptcha-container',
        {
          size: 'invisible',
        },
      )

      window.recaptchaWidgetId = await window.recaptchaVerifier.render()
    }
  } catch (error) {
    logError(error)
  }
}

export default auth

export const hideRecaptcha = (): void => {
  const recaptchaDiv = document.getElementById('recaptcha-container')

  if (recaptchaDiv) {
    recaptchaDiv.style.visibility = 'hidden'
  }
}
