import React from 'react'
import { Box, VStack, Text } from '@chakra-ui/react'

interface AmountAndNoteProps {
  amount: string
  senderName: string
  senderNote: string
}

const AmountAndNote: React.FC<AmountAndNoteProps> = ({
  amount,
  senderName,
  senderNote,
}) => {
  return (
    <VStack spacing={2} align='center'>
      <Text fontSize='3xl' fontWeight='bold' color='astraBlue.200'>
        ${amount} USD
      </Text>
      <Text color='gray.600'>Payment from {senderName}</Text>

      {senderNote && (
        <Box bg='gray.50' p={4} borderRadius='lg' w='full' mt={4}>
          <Text color='gray.500' fontSize='sm' mb={1} textAlign='center'>
            Note from sender:
          </Text>
          <Text
            color='gray.700'
            fontSize='sm'
            fontStyle='italic'
            textAlign='center'
          >
            &ldquo;{senderNote}&rdquo;
          </Text>
        </Box>
      )}
    </VStack>
  )
}

export default AmountAndNote
