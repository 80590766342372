import React, { RefObject, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import * as queryString from 'query-string'
import { Composition } from 'atomic-layout'
import { navigate } from '@reach/router'

import { Button, Container, Text } from 'src/atoms'
import { selectUser } from 'src/features/auth/authSlice'
import { UserStatus } from 'src/features//auth/types'
import {
  selectInquiryId,
  selectKycProvider,
  selectInquirySessionToken,
} from 'src/features/verify/verifySlice'
import {
  selectIframeOrigin,
  sendClosedMessage,
} from 'src/features/sdkMessaging/sdkMessagingSlice'
import { useAppDispatch } from 'src/redux/store'
import { createClient } from 'src/services/Persona'
import { Client as PersonaClient } from 'persona'
import { KycProviderOptions } from './types'

interface VerifyDocumentProps {
  location: Location
}

const VerifyDocument: React.FC<VerifyDocumentProps> = ({ location }) => {
  const dispatch = useAppDispatch()
  const iframeOrigin = useSelector(selectIframeOrigin)
  const embeddedPersonaClientRef: RefObject<PersonaClient> =
    useRef<PersonaClient>(null)
  const [isPersonaSDKLoading, setPersonaSDKLoading] = useState(false)

  const {
    continue_to: continueTo,
    redirect_uri: redirectUri,
    return_to: returnTo,
  } = queryString.parse(location.search)

  const { status } = useSelector(selectUser)

  const kycProvider = useSelector(selectKycProvider)
  const inquiryId = useSelector(selectInquiryId)
  const sessionToken = useSelector(selectInquirySessionToken)

  const navigateAway = () => {
    const navigateTo = (continueTo || returnTo || redirectUri || '/') as string
    navigate(navigateTo)
  }

  /**
   * Starts the KYC flow via the Persona Client.
   */
  const startPersonaKycFlow = () => {
    const completePersonaKycFlow = () => {
      navigateAway()
    }

    const loadPersonaKycFlow = () => {
      setPersonaSDKLoading(true)
    }

    const readyPersonaKycFlow = () => {
      setPersonaSDKLoading(false)
    }

    createClient({
      embeddedPersonaClientRef,
      inquiryId,
      sessionToken,
      onComplete: completePersonaKycFlow,
      onLoad: loadPersonaKycFlow,
      onReady: readyPersonaKycFlow,
    })
  }

  if (
    status !== UserStatus.DOCUMENT ||
    kycProvider !== KycProviderOptions.PERSONA
  ) {
    if (iframeOrigin) {
      dispatch(sendClosedMessage())
    } else {
      navigateAway()
    }
  }

  return (
    <Composition
      areas={`
          title
          subtitle
          form
        `}
      as={Container}
    >
      {({ Title, Subtitle, Form }) => (
        <>
          <Title marginBottom={3}>
            <Text fontFamily='heavy' size='large'>
              Upload Document
            </Text>
          </Title>
          <Subtitle marginBottom={15}>
            <Text
              color='textSubtle'
              fontFamily='medium'
              size='small'
              style={{ whiteSpace: 'pre-line' }}
            >
              You&apos;ll be guided through a simple process to upload the
              necessary documents for verification. Please ensure you have your
              identification documents ready to ensure a smooth and efficient
              verification process.
            </Text>
          </Subtitle>
          <Form>
            <Button
              type='button'
              onClick={startPersonaKycFlow}
              fullWidth
              style={{ marginTop: 20 }}
            >
              {isPersonaSDKLoading ? 'Loading...' : 'Continue'}
            </Button>
          </Form>
        </>
      )}
    </Composition>
  )
}

export default VerifyDocument
