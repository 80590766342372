import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState, useEffect } from 'react'
import {
  ConfirmationResult,
  RecaptchaVerifier,
  getAuth,
  onAuthStateChanged,
} from 'firebase/auth'
import { FirebaseError } from 'firebase/app'
import toast from 'react-hot-toast'
import apiRequest from 'src/utils/api'
import { signInWithPhoneNumber, resetRecaptcha } from 'src/utils/firebase'
import { useNavigate } from 'react-router-dom'
import { handleAuthError } from 'src/payment-links/utils/error'
import { toastSuccess } from 'src/payment-links/utils/toast'

export interface PaymentLinkData {
  uuid: string
  amount: number
  currency: string
  description: string
  status: string
}

export const paymentLinkKeys = {
  all: ['payment-links'] as const,
  detail: (uuid: string) => [...paymentLinkKeys.all, 'detail', uuid] as const,
}

export const authKeys = {
  all: ['auth'] as const,
  session: () => [...authKeys.all, 'session'] as const,
}

interface AuthStore {
  token: string | null
  isAuthenticated: boolean
}

/**
 * Ensure all fields are appended and default values are non-nullable
 */
const createDefaultPaymentLinkState = (response: any): PaymentLinkData => {
  return {
    uuid: response.uuid || '',
    amount: response.amount || 0,
    currency: response.currency || 'USD',
    description: response.description || '',
    status: response.status || 'pending',
  }
}

/**
 * Hook to fetch payment link data
 */
export function usePaymentLink(uuid: string | undefined) {
  const queryClient = useQueryClient()

  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: paymentLinkKeys.detail(uuid || ''),
    queryFn: async () => {
      if (!uuid) {
        return null
      }

      const response = await apiRequest({
        method: 'get',
        route: `v1/payment-links/${uuid}`,
        data: {},
      })

      return createDefaultPaymentLinkState(response)
    },
    enabled: !!uuid,
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 10 * 60 * 1000, // 10 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: true,
    networkMode: 'offlineFirst',
  })

  return {
    paymentLink: data,
    isLoading: isLoading && !data,
    isFetching,
    error: error as Error | null,
    refetch: () =>
      queryClient.invalidateQueries({
        queryKey: paymentLinkKeys.detail(uuid || ''),
      }),
  }
}

interface SubmitCardPayload {
  cardNumber: string
  expirationDate: string
  cvv: string
  nameOnCard: string
  zipCode: string
}

/**
 * Hook to submit card payment for a payment link
 */
export function useSubmitCardPayment() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      uuid,
      ...cardData
    }: SubmitCardPayload & { uuid: string }) => {
      const response = await apiRequest({
        method: 'post',
        route: `v1/payment-links/${uuid}/pay`,
        data: {
          card_number: cardData.cardNumber,
          expiration_date: cardData.expirationDate,
          cvv: cardData.cvv,
          name_on_card: cardData.nameOnCard,
          zip_code: cardData.zipCode,
        },
      })

      return createDefaultPaymentLinkState(response)
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: paymentLinkKeys.detail(variables.uuid),
      })
    },
    onError: () => {
      toast.error('Failed to process payment')
    },
  })
}

interface AuthState {
  isVerifying: boolean
  phoneNumber: string | null
  error: string | null
}

/**
 * hook for handling payment link phone verification flow
 */
export function usePaymentLinkAuth() {
  const auth = getAuth()
  const [authState, setAuthState] = useState<AuthState>({
    isVerifying: false,
    phoneNumber: null,
    error: null,
  })
  const [authConfirmationResult, setAuthConfirmationResult] =
    useState<ConfirmationResult>()
  const navigate = useNavigate()

  // verify code
  const verifyCode = useMutation({
    mutationFn: async (code: string) => {
      if (!authConfirmationResult) {
        setAuthState(prev => ({
          ...prev,
          isVerifying: false,
          error: 'no auth confirmation result',
        }))
        return null
      }

      try {
        setAuthState(prev => ({ ...prev, isVerifying: true, error: null }))

        const result = await authConfirmationResult.confirm(code)
        const token = await result.user.getIdToken()

        setAuthState(prev => ({
          ...prev,
          isVerifying: false,
        }))

        // on successful verification, navigate to payment details
        navigate('/payment-details', {
          state: {
            amount: '250.00',
            senderName: 'XYZ',
            senderNote:
              'Thank you for your help with the project last week. Really appreciate your dedication and hard work!',
            recipientName: 'Sarah Johnson',
            recipientEmail: 'sarah.johnson@email.com',
            recipientPhone: authState.phoneNumber,
            token,
          },
        })

        return token
      } catch (error: any) {
        if (error instanceof FirebaseError) {
          setAuthState(prev => ({
            ...prev,
            isVerifying: false,
            error: error.message,
          }))
          handleAuthError(error)
        }
        return null
      }
    },
  })

  // send verification code
  const sendCode = useMutation({
    mutationFn: async (phone: string) => {
      try {
        setAuthState(prev => ({ ...prev, isVerifying: true, error: null }))

        // ensure recaptcha is initialized
        if (!window.recaptchaVerifier) {
          window.recaptchaVerifier = new RecaptchaVerifier(
            auth,
            'recaptcha-container',
            {
              size: 'invisible',
            },
          )
          window.recaptchaWidgetId = await window.recaptchaVerifier.render()
        }

        const confirmationResult = await signInWithPhoneNumber(
          phone,
          window.recaptchaVerifier,
        )

        setAuthConfirmationResult(confirmationResult)
        setAuthState(prev => ({
          ...prev,
          isVerifying: false,
          phoneNumber: phone,
        }))

        toastSuccess('Verification code sent!')

        return true
      } catch (error: any) {
        if (error instanceof FirebaseError) {
          setAuthState(prev => ({
            ...prev,
            isVerifying: false,
            error: error.message,
          }))
          handleAuthError(error)
        }
        await resetRecaptcha()
        return false
      }
    },
  })

  return {
    authState,
    sendCode,
    verifyCode,
    // expose these for UI state management
    isVerifying: authState.isVerifying,
    phoneNumber: authState.phoneNumber,
    error: authState.error,
    // this helps UI know which screen to show
    hasConfirmationResult: !!authConfirmationResult,
  }
}

/**
 * Hook to manage Firebase session token
 */
export function useFirebaseSession() {
  const queryClient = useQueryClient()
  const auth = getAuth()

  // set up auth state listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      // when user signs out, clear the token from cache
      if (!user) {
        queryClient.setQueryData(authKeys.session(), {
          token: null,
          isAuthenticated: false,
        })
        return
      }

      // when user signs in, immediately retrieve and cache the token
      const token = await user.getIdToken(true)
      queryClient.setQueryData(authKeys.session(), {
        token,
        isAuthenticated: true,
      })
    })

    return () => unsubscribe()
  }, [auth, queryClient])

  return useQuery<AuthStore>({
    queryKey: authKeys.session(),
    queryFn: async () => {
      const user = auth.currentUser
      if (!user) {
        return {
          token: null,
          isAuthenticated: false,
        }
      }

      const token = await user.getIdToken(true)
      return {
        token,
        isAuthenticated: true,
      }
    },
    initialData: {
      token: null,
      isAuthenticated: false,
    },
    enabled: true,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 10 * 60 * 1000, // 10 minutes
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  })
}
