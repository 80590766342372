import { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { logout, selectIsLoggedIn } from 'src/features/auth/authSlice'
import { useAppDispatch } from 'src/redux/store'

const INACTIVITY_TIME_OUT_INTERVAL = 600000 // 10 minutes
const INACTIVITY_MODAL_VISIBILITY_INTERVAL_SECONDS = 60 // 1 minute

const useInactivityTimeout = () => {
  const dispatch = useAppDispatch()
  const [lastActivity, setLastActivity] = useState(Date.now())
  const [isInactivityModalVisible, setInactivityModalVisible] = useState(false)

  const [inactivityModalCountdown, setInactivityModalCountdown] = useState(
    INACTIVITY_MODAL_VISIBILITY_INTERVAL_SECONDS,
  )

  const [isConfirmedSignoutModalVisible, setConfirmedSignoutVisible] =
    useState(false)

  const timerIdRef = useRef(null)

  const isLoggedIn = useSelector(selectIsLoggedIn)

  const onHandleActivity = () => {
    setLastActivity(Date.now())
  }

  const onHandleInactivity = useCallback(() => {
    if (isLoggedIn) {
      clearTimeout(timerIdRef.current as any)
      setInactivityModalVisible(true)
    }
  }, [isLoggedIn])

  const onResetInactivityModalTimer = useCallback(() => {
    setInactivityModalCountdown(INACTIVITY_MODAL_VISIBILITY_INTERVAL_SECONDS)
  }, [])

  const onDismiss = () => {
    setInactivityModalVisible(false)
    setConfirmedSignoutVisible(false)
  }

  const onHandleSignout = useCallback(() => {
    dispatch(logout({ shouldRedirect: false, clearParams: true }))
    onDismiss()
  }, [dispatch])

  // used to start the inactivity countdown every second. If the current time minus the last time
  // of activity is greater than the timeout interval, the inactivity handler is called.
  useEffect(() => {
    const checkInactivity = () => {
      if (Date.now() - lastActivity > INACTIVITY_TIME_OUT_INTERVAL) {
        onHandleInactivity()
      }
    }

    timerIdRef.current = setInterval(checkInactivity, 1000) as any

    return () => {
      clearInterval(timerIdRef.current as any)
    }
  }, [lastActivity, onHandleInactivity])

  // used to update the last activity time whenever the user moves the mouse or presses a key.
  // This is how we track that the user is still active.
  useEffect(() => {
    window.addEventListener('mousemove', onHandleActivity)
    window.addEventListener('keydown', onHandleActivity)
    return () => {
      window.removeEventListener('mousemove', onHandleActivity)
      window.removeEventListener('keydown', onHandleActivity)
    }
  }, [])

  useEffect(() => {
    if (isInactivityModalVisible) {
      const timer = setInterval(() => {
        setInactivityModalCountdown(count => count - 1)
      }, 1000)
      return () => {
        clearInterval(timer)
      }
    } else {
      onResetInactivityModalTimer()
    }
  }, [
    isInactivityModalVisible,
    onResetInactivityModalTimer,
    setInactivityModalCountdown,
  ])

  useEffect(() => {
    if (inactivityModalCountdown === 0) {
      onHandleSignout()
      setConfirmedSignoutVisible(true)
    }
  }, [inactivityModalCountdown, onHandleSignout, setConfirmedSignoutVisible])

  return {
    inactivityModalCountdown,
    isConfirmedSignoutModalVisible,
    isInactivityModalVisible,
    onDismiss,
    onHandleSignout,
  }
}

export default useInactivityTimeout
