import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  unwrapResult,
} from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import apiRequest from '../../utils/api'
import {
  extractOAuthClients,
  removeOAuthClientsFromArr,
} from '../../utils/oauthClients'
import { setOAuthClients } from '../clients/clientsSlice'

const routinesAdapter = createEntityAdapter({
  sortComparer: (a, b) => new Date(b.created) - new Date(a.created),
})

export const getRoutines = createAsyncThunk(
  'routines/getAll',
  async (empty, { dispatch }) => {
    const { actions: routines = [] } = await dispatch(
      apiRequest({
        method: 'get',
        route: 'actions',
      }),
    ).then(unwrapResult)

    const clients = extractOAuthClients(routines)

    const editedRoutines = removeOAuthClientsFromArr(routines)

    dispatch(setOAuthClients(clients))

    return editedRoutines
  },
)

export const editRoutine = createAsyncThunk(
  'routines/edit',
  async ({ routine }, { dispatch }) => {
    const response = await dispatch(
      apiRequest({
        method: 'put',
        route: `actions/${routine.id}`,
        data: routine,
      }),
    ).then(unwrapResult)
    toast.success('Your routine has been updated.')
    return response
  },
)

export const removeRoutine = createAsyncThunk(
  'routines/remove',
  async ({ id }, { dispatch }) => {
    await dispatch(
      apiRequest({
        method: 'delete',
        route: `actions/${id}`,
      }),
    ).then(unwrapResult)
    toast.success('Your routine has been removed.')
    dispatch(getRoutines())
  },
)

const routinesSlice = createSlice({
  name: 'routines',
  initialState: routinesAdapter.getInitialState({
    loading: false,
    isRemoving: false,
  }),
  extraReducers: builder => {
    builder
      .addCase(getRoutines.pending, state => {
        state.loading = true
      })
      .addCase(getRoutines.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload) {
          routinesAdapter.setAll(state, action.payload)
        }
      })
      .addCase(getRoutines.rejected, state => {
        state.loading = false
      })
      .addCase(editRoutine.fulfilled, (state, action) => {
        if (action.payload) {
          routinesAdapter.upsertOne(state, action.payload)
        }
      })
      .addCase(removeRoutine.pending, state => {
        state.isRemoving = true
      })
      .addCase(removeRoutine.fulfilled, state => {
        state.isRemoving = false
      })
      .addCase(removeRoutine.rejected, state => {
        state.isRemoving = false
      })
  },
})

export const routinesSelectors = routinesAdapter.getSelectors(
  state => state.routines,
)
export const selectRoutinesLoading = state => state.routines.loading
export const selectIsRemovingRoutine = state => state.routines.isRemoving

export default routinesSlice.reducer
