import React from 'react'
import { useToast, ToastProvider, UseToastOptions } from '@chakra-ui/react'
import { createContext, useEffect } from 'react'

const ToasterContext = createContext<ReturnType<typeof useToast> | null>(null)

let globalToast: ((options: UseToastOptions) => void) | null = null

export function Toaster() {
  const toast = useToast({
    position: 'top',
    duration: 3000,
    variant: 'solid',
    isClosable: true,
  })

  useEffect(() => {
    globalToast = toast

    return () => {
      globalToast = null
    }
  }, [toast])

  return (
    <ToasterContext.Provider value={toast}>
      <ToastProvider />
    </ToasterContext.Provider>
  )
}

export const toaster = {
  create: (options: UseToastOptions) => {
    if (globalToast) {
      return globalToast(options)
    } else {
      console.warn('Toast was called before it was initialized')
    }
  },
}

export const ToastProviderWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <>
      {children}
      <Toaster />
    </>
  )
}
