import { DefaultTheme } from 'styled-components'

const palette = {
  purple: '#593d89',
  blue: '#042E8F',
  green: '#47D665',
  red: '#FC5F5F',
  almostBlack: '#1D1D26',
  white: '#ffffff',
  black: '#000000',
  gray: '#c2c6cc',
  grayMedium: '#707080',
  offWhite: '#f7f8fd',
}

const theme = {
  colors: {
    accent: palette.almostBlack,
    background: palette.white,
    backgroundInverse: palette.black,
    backgroundTinted: palette.offWhite,
    border: palette.gray,
    info: palette.blue,
    primary: palette.almostBlack,
    text: palette.almostBlack,
    textInverse: palette.white,
    textSubtle: palette.grayMedium,
    success: palette.green,
    error: palette.red,
  },
  fonts: {
    light: 'Avenir-Light',
    book: 'Avenir-Book',
    medium: 'Avenir-Medium',
    heavy: 'Avenir-Heavy',
  },
  text: {
    xsmall: {
      size: '.8em',
      height: '1.5em',
    },
    small: {
      size: '.9em',
      height: '1.5em',
    },
    medium: {
      size: '1em',
      height: '1.5em',
    },
    large: {
      size: '1.3em',
      height: '1.5em',
    },
    xlarge: {
      size: '1.5em',
      height: '1.5em',
    },
    xxlarge: {
      size: '2em',
      height: '1.5em',
    },
  },
  button: {
    small: {
      pad: {
        horizontal: '7px',
        vertical: '3px',
      },
      text: 'small',
    },
    medium: {
      pad: {
        horizontal: '15px',
        vertical: '10px',
      },
      text: 'medium',
    },
  },
  spinner: {
    small: '30px',
    medium: '48px',
    large: '75px',
  },
  logo: {
    small: {
      height: '30px',
    },
    medium: {
      height: '47px',
    },
  },
  institutionLogo: {
    small: {
      radius: '12px',
      size: '24px',
    },
    medium: {
      radius: '16px',
      size: '32px',
    },
    large: {
      radius: '22px',
      size: '44px',
    },
  },
}

export type CustomThemeType = typeof theme

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
  export interface DefaultTheme extends CustomThemeType {}
}

export const astraTheme: DefaultTheme = theme
