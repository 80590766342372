import React, { useState } from 'react'
import { Box, VStack, Flex, Button, useToast } from '@chakra-ui/react'
import Logo from 'src/components/Logo'
import StatusModal from 'src/payment-links/components/StatusModal'
import AmountAndNote from 'src/payment-links/features/payment/components/AmountAndNote'
import RecipientInfo from 'src/payment-links/features/payment/components/RecipientInfo'
import CardDetails from 'src/payment-links/features/payment/components/CardDetails'
import BenefitsList from 'src/payment-links/features/payment/components/BenefitsList'

type DestinationPaymentMethod = 'card'

type ModalState = {
  isOpen: boolean
  variant: 'success' | 'error'
  paymentMethod: DestinationPaymentMethod
} | null

interface PaymentDetailsProps {
  amount: string
  senderName: string
  senderNote: string
  recipientName: string
  recipientEmail: string
  recipientPhone: string
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  amount,
  senderName,
  senderNote,
  recipientName,
  recipientEmail,
  recipientPhone,
}) => {
  const [modalState, setModalState] = useState<ModalState>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formData, setFormData] = useState({
    cardName: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    zipCode: '',
  })
  const toast = useToast()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const validateForm = () => {
    const requiredFields = [
      'cardName',
      'cardNumber',
      'expiryDate',
      'cvv',
      'zipCode',
    ]
    const emptyFields = requiredFields.filter(
      field => !formData[field as keyof typeof formData],
    )

    if (emptyFields.length > 0) {
      toast({
        title: 'Missing Information',
        description: 'Please fill in all required fields',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      return false
    }
    return true
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // prevent double submission
    if (isSubmitting) return

    // validate form
    if (!validateForm()) return

    setIsSubmitting(true)

    try {
      // TODO: Replace with real API call to submit card and trigger routine
      await new Promise(resolve => setTimeout(resolve, 1500))
      const isSuccess = Math.random() > 0.5

      if (isSuccess) {
        setModalState({
          isOpen: true,
          variant: 'success',
          paymentMethod: 'card',
        })
      } else {
        setModalState({
          isOpen: true,
          variant: 'error',
          paymentMethod: 'card',
        })
      }
    } catch {
      setModalState({
        isOpen: true,
        variant: 'error',
        paymentMethod: 'card',
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const closeModal = () => setModalState(null)

  const getModalProps = (
    variant: 'success' | 'error',
    paymentMethod: DestinationPaymentMethod,
  ) => {
    const messages = {
      card: {
        success: {
          title: 'Payment Successful!',
          description:
            'The funds have been sent to your card and will arrive shortly.',
          buttonText: 'Done',
        },
        error: {
          title: 'Payment Failed',
          description:
            'Something went wrong. Please verify your card info and try again.',
          buttonText: 'Try Again',
        },
      },
    }

    return messages[paymentMethod][variant]
  }

  return (
    <Box minH='100vh' bg='orange' width='100vw'>
      {modalState && (
        <StatusModal
          isOpen={modalState.isOpen}
          onClose={closeModal}
          variant={modalState.variant}
          {...getModalProps(modalState.variant, modalState.paymentMethod)}
        />
      )}

      {/* Header with Logo - Full width */}
      <Box
        w='full'
        bg='white'
        borderBottom='1px solid'
        borderColor='gray.100'
        position='sticky'
        top={0}
        zIndex={10}
      >
        <Flex h='56px' px={4} align='center' justify='center'>
          <Logo size='small' />
        </Flex>
      </Box>

      {/* Main Content */}
      <Box minH='100vh' bg='gray.50'>
        <Box w='full' maxW='400px' mx='auto' p={6}>
          <Box bg='white' borderRadius='lg' p={6} boxShadow='sm'>
            <form onSubmit={handleSubmit}>
              <VStack spacing={6}>
                <AmountAndNote
                  amount={amount}
                  senderName={senderName}
                  senderNote={senderNote}
                />

                <RecipientInfo
                  recipientName={recipientName}
                  recipientEmail={recipientEmail}
                  recipientPhone={recipientPhone}
                />

                <CardDetails
                  formData={formData}
                  handleInputChange={handleInputChange}
                />

                <Box width='full' mb={2}>
                  <Button
                    type='submit'
                    w='full'
                    h={12}
                    colorScheme='primary'
                    borderRadius={8}
                    isLoading={isSubmitting}
                    loadingText='Processing...'
                  >
                    Receive Payment
                  </Button>
                </Box>

                <BenefitsList />
              </VStack>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PaymentDetails
