import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  unwrapResult,
} from '@reduxjs/toolkit'
import { OAuthClient } from 'src/features/auth/types'
import { RootState } from 'src/redux/reducer'
import apiRequest from 'src/utils/api'

const clientsAdapter = createEntityAdapter({
  selectId: (oAuthClient: OAuthClient) => oAuthClient.client_id,
})

export const getClients = createAsyncThunk(
  'clients/getAll',
  async (empty, { dispatch }) => {
    const { oauth_clients: clients = [] } = (await dispatch(
      apiRequest({
        method: 'get',
        route: 'clients',
      }),
    ).then(unwrapResult)) as { oauth_clients: OAuthClient[] }

    return clients
  },
)

const clientsSlice = createSlice({
  name: 'clients',
  initialState: clientsAdapter.getInitialState({
    loading: false,
  }),
  reducers: {
    setOAuthClients: (state, action) => {
      if (action.payload) {
        clientsAdapter.upsertMany(state, action.payload)
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getClients.pending, state => {
        state.loading = true
      })
      .addCase(getClients.fulfilled, (state, action) => {
        if (action.payload) {
          clientsAdapter.setAll(state, action.payload)
        }
        state.loading = false
      })
      .addCase(getClients.rejected, state => {
        state.loading = false
      })
  },
})

export const { setOAuthClients } = clientsSlice.actions

export const clientsSelectors = clientsAdapter.getSelectors(
  (state: RootState) => state.clients,
)
export const selectOAuthClientsLoading = (state: RootState): boolean =>
  state.clients.loading

export default clientsSlice.reducer
