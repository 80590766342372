import React from 'react'
import { useSelector } from 'react-redux'
import { navigate, useLocation } from '@reach/router'
import * as queryString from 'query-string'

import { useAppDispatch } from '../../redux/store'
import { Button } from '../../atoms'
import { selectClient } from '../auth/authSlice'
import { cardsSelectors } from './cardsSlice'
import { institutionsSelectors } from './institutionsSlice'
import {
  selectIframeOrigin,
  sendClosedMessage,
} from '../sdkMessaging/sdkMessagingSlice'

interface ContinueButtonProps {
  continueOverride?: boolean
}

const ContinueButton: React.FC<ContinueButtonProps> = ({
  continueOverride = false,
}) => {
  const location = useLocation()

  const dispatch = useAppDispatch()

  const iframeOrigin = useSelector(selectIframeOrigin)
  const clientName = useSelector(selectClient).name
  const isProcessorTokenEnabled =
    useSelector(selectClient).processorTokenEnabled
  const debitCards = useSelector(cardsSelectors.selectAll)
  const institutions = useSelector(institutionsSelectors.selectAll)

  const {
    client_id: clientId,
    redirect_uri: redirectUri,
    continue_to: continueTo,
    return_to: returnTo,
    debit_direct: debitDirectParam,
    state,
  } = queryString.parse(location.search)

  const returnToSplit =
    returnTo && typeof returnTo === 'string' ? returnTo.split('?')[1] : ''
  const returnToParts = returnToSplit ? queryString.parse(returnToSplit) : {}
  const debitDirect =
    debitDirectParam === 'true' || returnToParts.debit_direct === 'true'

  if (debitDirect) return null

  if (
    (isProcessorTokenEnabled ||
      debitCards.length ||
      institutions.length ||
      continueOverride) &&
    (continueTo || returnTo)
  ) {
    return (
      <Button
        onClick={() => navigate((continueTo as string) || (returnTo as string))}
        fullWidth
      >
        Continue
      </Button>
    )
  }

  if (clientId && redirectUri && !continueTo && !returnTo) {
    const oAuthState = state
      ? `${redirectUri.indexOf('?') !== -1 ? '&' : '?'}state=${state}`
      : ''
    const link = `${redirectUri}${oAuthState}`
    return (
      <Button
        onClick={() => {
          if (iframeOrigin) {
            dispatch(sendClosedMessage())
          } else {
            navigate(link)
          }
        }}
        fullWidth
      >
        {`Continue${clientName ? ` to ${clientName}` : ''}`}
      </Button>
    )
  }

  return null
}

export default ContinueButton
