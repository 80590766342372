import React from 'react'
import { VStack } from '@chakra-ui/react'
import FormNote from 'src/payment-links/components/FormNote'

const BenefitsList: React.FC = () => {
  return (
    <VStack spacing={4} align='stretch'>
      <FormNote
        icon='shield-checkmark-outline'
        title='Bank-level Security'
        description='Your financial data is protected with industry-standard encryption and security protocols.'
      />
      <FormNote
        icon='lock-closed-outline'
        title='PCI DSS Compliant'
        description='We follow strict security standards to ensure your payment information is always safe.'
      />
      <FormNote
        icon='finger-print'
        title='Fraud Prevention'
        description='Advanced fraud detection systems monitor transactions 24/7 to prevent unauthorized access.'
      />
    </VStack>
  )
}

export default BenefitsList
