import React from 'react'
import { createRoot } from 'react-dom/client'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_VERSION,
  releaseStage: process.env.REACT_APP_RELEASE_STAGE,
  enabledReleaseStages: ['production', 'staging', 'testing', 'sandbox'],
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const ErrorFallBack = () => (
  <div>
    <h1>Oops, something went wrong.</h1>
  </div>
)

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <ErrorBoundary FallbackComponent={ErrorFallBack}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ErrorBoundary>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
