import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Composition } from 'atomic-layout'

import EINField from 'src/components/EINField'
import PhoneNumberDisplay from 'src/components/PhoneNumberDisplay'
import PhoneNumberField from 'src/components/PhoneNumberField'
import SelectField from 'src/components/SelectField'
import { Button, Container, Input, Text } from 'src/atoms'
import { selectBusinessInfo } from 'src/features/verify/verifySlice'
import {
  BusinessType,
  businessTypes,
  getBusinessTypeViaId,
} from 'src/features/verify/types'
import useBusinessInfoForm, {
  BusinessInfoFormKeys,
} from 'src/features/verify/business/BusinessInfo/useBusinessInfoForm'
import isURL from 'validator/lib/isURL'

const areasMobile = `
  title
  subtitle
  form
`

const formAreasMobile = `
  businessName
  doingBusinessAs
  website
  phone
  businessType
  ein
  continue
  note
`

const completedAreasMobile = `
  businessName edit
  doingBusinessAs edit
  website edit
  phone edit
`

interface VerifyBusinessInfoProps {
  isReadOnly: boolean
}

const BusinessInfo: React.FC<VerifyBusinessInfoProps> = ({ isReadOnly }) => {
  const {
    defaultValues,
    errors: formErrors,
    form,
    isSubmitDisabled,
    onEdit,
    onSubmit,
  } = useBusinessInfoForm()

  const errors = formErrors as any

  const [businessTypeId, setBusinessTypeId] = useState('')
  const [businessTypeValue, setBusinessTypeValue] = useState('')
  const businessTypeOptions = businessTypes.map(({ id, name }) => {
    return { label: name, value: id }
  })

  // selectors
  const { businessName, doingBusinessAs, businessType, website, phoneNumber } =
    useSelector(selectBusinessInfo)

  useEffect(() => {
    if (businessType) {
      setBusinessTypeValue(businessType)
    } else if (businessTypeId) {
      setBusinessTypeValue(getBusinessTypeViaId(businessTypeId)?.businessType)
    }
  }, [businessTypeId, businessType])

  if (isReadOnly) {
    return (
      <Composition
        areas={completedAreasMobile}
        as={Container}
        gap={3}
        alignItems='center'
        justifyContent='space-between'
      >
        {Areas => (
          <>
            <Areas.BusinessName>
              <Text fontFamily='medium' color='textSubtle'>
                {businessName}
              </Text>
            </Areas.BusinessName>
            {doingBusinessAs && (
              <Areas.DoingBusinessAs>
                <Text fontFamily='medium' color='textSubtle'>
                  {doingBusinessAs}
                </Text>
              </Areas.DoingBusinessAs>
            )}
            {website && (
              <Areas.Website>
                <Text fontFamily='medium' color='textSubtle'>
                  {website}
                </Text>
              </Areas.Website>
            )}
            {phoneNumber && (
              <Areas.Phone>
                <PhoneNumberDisplay phoneNumber={phoneNumber} />
              </Areas.Phone>
            )}
            <Areas.Edit>
              <Text
                onClick={onEdit}
                color='primary'
                cursor='pointer'
                fontFamily='medium'
              >
                Edit
              </Text>
            </Areas.Edit>
          </>
        )}
      </Composition>
    )
  }

  return (
    <Composition areas={areasMobile} as={Container}>
      {Areas => (
        <>
          <Areas.Title marginBottom={3}>
            <Text fontFamily='heavy' size='large'>
              Verify your Business
            </Text>
          </Areas.Title>
          <Areas.Subtitle marginBottom={15}>
            <Text fontFamily='medium' size='small' color='textSubtle'>
              Almost there! We need to gather some additional information about
              your business.
            </Text>
          </Areas.Subtitle>
          <Areas.Form>
            <form onSubmit={onSubmit}>
              <Composition areas={formAreasMobile} gap={10}>
                {FormAreas => (
                  <>
                    <FormAreas.BusinessName>
                      <Input
                        id={BusinessInfoFormKeys.BUSINESS_NAME}
                        type='text'
                        placeholder='Business Name*'
                        defaultValue={
                          defaultValues[BusinessInfoFormKeys.BUSINESS_NAME]
                        }
                        bordercolor={
                          errors[BusinessInfoFormKeys.BUSINESS_NAME] && 'error'
                        }
                        {...form.register(BusinessInfoFormKeys.BUSINESS_NAME, {
                          required: true,
                        })}
                      />
                      {errors[BusinessInfoFormKeys.BUSINESS_NAME] &&
                        errors[BusinessInfoFormKeys.BUSINESS_NAME].message && (
                          <Text color='error' size='xsmall'>
                            {
                              errors[BusinessInfoFormKeys.BUSINESS_NAME]
                                .message as string
                            }
                          </Text>
                        )}
                    </FormAreas.BusinessName>
                    <FormAreas.DoingBusinessAs>
                      <Input
                        id={BusinessInfoFormKeys.DOING_BUSINESS_AS}
                        type='text'
                        placeholder='Doing Business As (Optional)'
                        defaultValue={
                          defaultValues[BusinessInfoFormKeys.DOING_BUSINESS_AS]
                        }
                        bordercolor={
                          errors[BusinessInfoFormKeys.DOING_BUSINESS_AS] &&
                          'error'
                        }
                        {...form.register(
                          BusinessInfoFormKeys.DOING_BUSINESS_AS,
                          {
                            required: true,
                          },
                        )}
                      />
                      {errors[BusinessInfoFormKeys.DOING_BUSINESS_AS] && (
                        <Text color='error' size='xsmall'>
                          {
                            errors[BusinessInfoFormKeys.DOING_BUSINESS_AS]
                              .message
                          }
                        </Text>
                      )}
                    </FormAreas.DoingBusinessAs>
                    <FormAreas.Website>
                      <Input
                        id={BusinessInfoFormKeys.WEBSITE}
                        type='text'
                        placeholder='Website (Optional)'
                        defaultValue={
                          defaultValues[BusinessInfoFormKeys.WEBSITE]
                        }
                        bordercolor={
                          errors[BusinessInfoFormKeys.WEBSITE] && 'error'
                        }
                        {...form.register(BusinessInfoFormKeys.WEBSITE, {
                          validate: value =>
                            value &&
                            !isURL(value, {
                              protocols: ['http', 'https'],
                              require_protocol: true,
                            })
                              ? 'Must be a valid URL beginning with http:// or https://'
                              : true,
                        })}
                      />
                      {errors[BusinessInfoFormKeys.WEBSITE] && (
                        <Text color='error' size='xsmall'>
                          {errors[BusinessInfoFormKeys.WEBSITE].message}
                        </Text>
                      )}
                    </FormAreas.Website>
                    <FormAreas.Phone>
                      <PhoneNumberField
                        control={form.control}
                        defaultValue={
                          defaultValues[BusinessInfoFormKeys.PHONE_NUMBER]
                        }
                        error={errors[BusinessInfoFormKeys.PHONE_NUMBER]}
                        id={BusinessInfoFormKeys.PHONE_NUMBER}
                        placeholder='Business Phone'
                        {...form.register(BusinessInfoFormKeys.PHONE_NUMBER)}
                      />
                      {errors[BusinessInfoFormKeys.PHONE_NUMBER] && (
                        <Text
                          color='error'
                          size='xsmall'
                          style={{ marginTop: 5 }}
                        >
                          {errors[BusinessInfoFormKeys.PHONE_NUMBER].message}
                        </Text>
                      )}
                    </FormAreas.Phone>
                    <FormAreas.BusinessType>
                      <SelectField
                        id={BusinessInfoFormKeys.BUSINESS_TYPE}
                        control={form.control}
                        defaultValue={
                          defaultValues[BusinessInfoFormKeys.BUSINESS_TYPE]
                        }
                        {...form.register(BusinessInfoFormKeys.BUSINESS_TYPE, {
                          validate: {
                            businessTypeRequired: (value: string) => {
                              if (value === '0') return 'required'
                              return undefined
                            },
                          },
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setBusinessTypeId(event.target.value)
                          },
                        })}
                        error={errors[BusinessInfoFormKeys.BUSINESS_TYPE]}
                        options={[
                          { label: 'Select Business Type...', value: '0' },
                          ...businessTypeOptions,
                        ]}
                      />
                      {errors[BusinessInfoFormKeys.BUSINESS_TYPE] && (
                        <Text color='error' size='xsmall'>
                          {errors[BusinessInfoFormKeys.BUSINESS_TYPE].message}
                        </Text>
                      )}
                    </FormAreas.BusinessType>
                    {businessTypeValue && (
                      <FormAreas.Ein>
                        <EINField
                          id={BusinessInfoFormKeys.EIN}
                          control={form.control}
                          isRequired={
                            businessTypeValue !==
                            BusinessType.SOLE_PROPRIETORSHIP
                          }
                          placeholder={`EIN ${
                            businessTypeValue ===
                            BusinessType.SOLE_PROPRIETORSHIP
                              ? '(Optional)'
                              : ''
                          }`}
                          defaultValue={
                            defaultValues[BusinessInfoFormKeys.EIN] as string
                          }
                          error={errors[BusinessInfoFormKeys.EIN]}
                          {...form.register(BusinessInfoFormKeys.EIN, {
                            required:
                              businessTypeValue !==
                              BusinessType.SOLE_PROPRIETORSHIP,
                          })}
                        />
                        {errors[BusinessInfoFormKeys.EIN] && (
                          <Text color='error' size='xsmall'>
                            {errors[BusinessInfoFormKeys.EIN].message}
                          </Text>
                        )}
                      </FormAreas.Ein>
                    )}
                    <FormAreas.Continue>
                      <Button
                        type='submit'
                        disabled={isSubmitDisabled}
                        fullWidth
                      >
                        Continue
                      </Button>
                    </FormAreas.Continue>
                    <FormAreas.Note>
                      <Text fontFamily='medium' size='small' color='text'>
                        *Business name must match the business name listed on
                        your official IRS business documentation.
                      </Text>
                    </FormAreas.Note>
                  </>
                )}
              </Composition>
            </form>
          </Areas.Form>
        </>
      )}
    </Composition>
  )
}

export default BusinessInfo
