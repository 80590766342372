import React from 'react'
import {
  Box,
  VStack,
  Text,
  Button,
  HStack,
  PinInput,
  PinInputField,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import Logo from 'src/components/Logo'
import FormNote from '../../components/FormNote'
import PhoneNumberField from '../../../components/PhoneNumberField'
import { clearPhoneNumberFormatting } from 'src/utils/format'
import { usePaymentLinkAuth } from '../../hooks'

interface FormValues {
  phoneNumber: string
}

const Auth: React.FC = () => {
  const { control, watch } = useForm<FormValues>()
  const phoneFormValue = watch('phoneNumber')

  const {
    sendCode,
    verifyCode,
    isVerifying,
    hasConfirmationResult,
    // error: _error,
  } = usePaymentLinkAuth()

  const [verificationCode, setVerificationCode] = React.useState('')
  const [isResending, setIsResending] = React.useState(false)

  const isPhoneValid = React.useMemo(() => {
    if (!phoneFormValue) return false
    // check if the phone number matches the format +1 (XXX) XXX-XXXX
    const phoneRegex = /^\+1\s\(\d{3}\)\s\d{3}-\d{4}$/
    return phoneRegex.test(phoneFormValue)
  }, [phoneFormValue])

  const handlePhoneSubmit = async () => {
    if (!isPhoneValid) return

    await sendCode.mutateAsync(clearPhoneNumberFormatting(phoneFormValue))
  }

  const handleVerifyCode = async () => {
    if (verificationCode.length !== 6) return

    await verifyCode.mutateAsync(verificationCode)
  }

  const handleResendCode = async () => {
    setIsResending(true)
    try {
      await handlePhoneSubmit()
    } finally {
      setIsResending(false)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && verificationCode.length === 6) {
      e.preventDefault()
      handleVerifyCode()
    }
  }

  return (
    <Box
      bg='white'
      w={{ base: '90%', sm: '400px' }}
      p={{ base: 4, sm: 6 }}
      borderRadius='lg'
      boxShadow='md'
    >
      <VStack spacing={6} textAlign='center' flex='1'>
        <VStack spacing={4} flex='1'>
          <Logo size='medium' />
          <Text fontSize='2xl' fontWeight='semibold'>
            XYZ wants to send you
          </Text>
          <Text fontSize='3xl' fontWeight='bold' color='astraBlue.200'>
            $250.00 USD
          </Text>
        </VStack>

        <VStack w='full' spacing={5} align='stretch'>
          <Box position='relative'>
            <form
              onSubmit={e => {
                e.preventDefault()
                handlePhoneSubmit()
              }}
            >
              <PhoneNumberField
                control={control}
                defaultValue=''
                error={undefined}
                id='phone-number'
                placeholder='Enter phone number'
                label='Phone Number'
                readOnly={hasConfirmationResult}
                name='phoneNumber'
              />
            </form>
            {hasConfirmationResult && (
              <Button
                position='absolute'
                right='10px'
                top='65%'
                transform='translateY(-50%)'
                bg='primary.500'
                variant='ghost'
                size='sm'
                color='white'
                height='32px'
                onClick={handleResendCode}
                fontSize='sm'
                borderRadius={8}
                _hover={{ bg: 'gray.100' }}
                _active={{ bg: 'gray.200' }}
                zIndex={2}
                isLoading={isResending}
                isDisabled={isVerifying}
              >
                Resend
              </Button>
            )}
          </Box>

          {hasConfirmationResult ? (
            <VStack w='full' spacing={6} align='stretch'>
              <VStack spacing={1} align='stretch'>
                <Text fontSize='sm' color='gray.600' textAlign='left'>
                  Verification Code
                </Text>
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    handleVerifyCode()
                  }}
                >
                  <div onKeyDown={handleKeyDown}>
                    <HStack spacing={2}>
                      <PinInput
                        otp
                        size='lg'
                        value={verificationCode}
                        onChange={setVerificationCode}
                      >
                        {[...Array(6)].map((_, i) => (
                          <PinInputField key={i} autoFocus={i === 0} />
                        ))}
                      </PinInput>
                    </HStack>
                  </div>
                </form>
              </VStack>
              <Button
                colorScheme='primary'
                w='full'
                h='48px'
                fontSize='md'
                isLoading={isVerifying}
                loadingText='Verifying...'
                onClick={handleVerifyCode}
                isDisabled={verificationCode.length !== 6}
              >
                Verify Code
              </Button>
            </VStack>
          ) : (
            <Button
              colorScheme='primary'
              w='full'
              h='48px'
              fontSize='md'
              onClick={handlePhoneSubmit}
              isLoading={isVerifying}
              loadingText='Sending...'
              isDisabled={!isPhoneValid}
            >
              Send Code
            </Button>
          )}
        </VStack>

        <FormNote
          icon='shield-checkmark-outline'
          title='Secure Verification'
          description="We'll send you a verification code via SMS to confirm your identity."
        />

        {/* Invisible reCAPTCHA container */}
        <div id='recaptcha-container' />
      </VStack>
    </Box>
  )
}

export default Auth
