import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  VStack,
  Circle,
  Icon,
  Text,
  Button,
  Box,
} from '@chakra-ui/react'
import { keyframes } from '@emotion/react'
import IonIcon from 'src/atoms/IonIcon'

// define animation keyframes
const shakeAnimation = keyframes`
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-4px); }
  75% { transform: translateX(4px); }
`

const successAnimation = keyframes`
  0% { transform: scale(0.8); opacity: 0; }
  50% { transform: scale(1.1); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
`

interface StatusModalProps {
  isOpen: boolean
  onClose: () => void
  variant: 'success' | 'error'
  title: string
  description: string
  buttonText: string
  onButtonClick?: () => void
  isSubmitting?: boolean
}

const variants = {
  success: {
    icon: 'checkmark-circle-outline',
    circleColor: 'green.100',
    iconColor: 'green.400',
  },
  error: {
    icon: 'warning-outline',
    circleColor: 'red.100',
    iconColor: 'red.500',
  },
}

const StatusModal: React.FC<StatusModalProps> = ({
  isOpen,
  onClose,
  variant,
  title,
  description,
  buttonText,
  onButtonClick,
  isSubmitting = false,
}) => {
  const { icon, circleColor, iconColor } = variants[variant]

  // animation for different states - just for fun :)
  const animation =
    variant === 'error'
      ? `${shakeAnimation} 0.5s ease`
      : `${successAnimation} 0.5s ease`

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset='slideInBottom'
      returnFocusOnClose
      closeOnEsc
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <ModalOverlay bg='blackAlpha.700' backdropFilter='blur(2px)' />
      <ModalContent
        w='360px'
        maxW='90vw'
        bg='white'
        borderRadius='lg'
        p={8}
        mx={4}
        boxShadow='xl'
      >
        <VStack spacing={6} align='center'>
          <Box animation={animation}>
            <Circle size='64px' bg={circleColor}>
              <Icon as={IonIcon} name={icon} color={iconColor} size={36} />
            </Circle>
          </Box>
          <VStack spacing={2}>
            <Text
              id='modal-title'
              fontSize='2xl'
              fontWeight='semibold'
              textAlign='center'
            >
              {title}
            </Text>
            <Text
              id='modal-description'
              fontSize='sm'
              color='gray.600'
              textAlign='center'
            >
              {description}
            </Text>
          </VStack>
          <Button
            w='full'
            h={14}
            bg='primary.500'
            color='white'
            _hover={{ bg: 'primary.600' }}
            _active={{ bg: 'primary.700' }}
            onClick={onButtonClick || onClose}
            borderRadius={8}
            isLoading={isSubmitting}
            loadingText={
              variant === 'error' ? 'Trying again...' : 'Processing...'
            }
            fontSize='md'
          >
            {buttonText}
          </Button>
        </VStack>
      </ModalContent>
    </Modal>
  )
}

export default StatusModal
