import React from 'react'
import {
  Box,
  VStack,
  Text,
  Icon,
  Flex,
  Input,
  FormControl,
  FormLabel,
  SimpleGrid,
  Link,
} from '@chakra-ui/react'
import IonIcon from 'src/atoms/IonIcon'

interface CardDetailsProps {
  formData: {
    cardName: string
    cardNumber: string
    expiryDate: string
    cvv: string
    zipCode: string
  }
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const CardDetails: React.FC<CardDetailsProps> = ({
  formData,
  handleInputChange,
}) => {
  return (
    <Box>
      <Flex align='center' mb={4} gap={2}>
        <Icon
          as={IonIcon}
          name='card-outline'
          color='astraBlue.200'
          size={15}
          aria-hidden='true'
        />
        <Text fontSize='sm' fontWeight='600'>
          Card Details
        </Text>
      </Flex>
      <Text fontSize='sm' color='gray.600' mb={6}>
        Enter your debit card details to receive funds instantly
      </Text>

      <VStack spacing={6}>
        <FormControl isRequired>
          <FormLabel fontSize='sm' color='gray.600'>
            Name on Card
          </FormLabel>
          <Input
            name='cardName'
            value={formData.cardName}
            onChange={handleInputChange}
            h={12}
            placeholder='John Smith'
            _focus={{ borderColor: 'primary.500' }}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel fontSize='sm' color='gray.600'>
            Card Number
          </FormLabel>
          <Box position='relative'>
            <Input
              name='cardNumber'
              value={formData.cardNumber}
              onChange={handleInputChange}
              h={12}
              placeholder='1234 5678 9012 3456'
              _focus={{ borderColor: 'primary.500' }}
            />
            <Icon
              as={IonIcon}
              name='card-outline'
              position='absolute'
              right={4}
              top='50%'
              transform='translateY(-50%)'
              color='gray.400'
              aria-hidden='true'
            />
          </Box>
        </FormControl>

        <SimpleGrid columns={2} spacing={4} w='full'>
          <FormControl isRequired>
            <FormLabel fontSize='sm' color='gray.600'>
              Expiration Date
            </FormLabel>
            <Input
              name='expiryDate'
              value={formData.expiryDate}
              onChange={handleInputChange}
              h={12}
              placeholder='MM/YY'
              _focus={{ borderColor: 'primary.500' }}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel fontSize='sm' color='gray.600'>
              CVV
            </FormLabel>
            <Input
              name='cvv'
              value={formData.cvv}
              onChange={handleInputChange}
              h={12}
              type='password'
              placeholder='123'
              _focus={{ borderColor: 'primary.500' }}
            />
          </FormControl>
        </SimpleGrid>

        <FormControl isRequired>
          <FormLabel fontSize='sm' color='gray.600'>
            ZIP Code
          </FormLabel>
          <Input
            name='zipCode'
            value={formData.zipCode}
            onChange={handleInputChange}
            h={12}
            placeholder='12345'
            _focus={{ borderColor: 'primary.500' }}
          />
        </FormControl>

        <Text fontSize='sm' color='gray.600'>
          By continuing, you agree to the{' '}
          <Link
            color='astraBlue.200'
            href='https://astrafi.com/terms/'
            target='_blank'
          >
            Terms & Conditions
          </Link>{' '}
          and authorize the payment.
        </Text>
      </VStack>
    </Box>
  )
}

export default CardDetails
