import { PatternFormat } from 'react-number-format'
import styled, { css } from 'styled-components'

interface InputProps {
  bordercolor?:
    | 'accent'
    | 'background'
    | 'backgroundInverse'
    | 'backgroundTinted'
    | 'border'
    | 'info'
    | 'primary'
    | 'text'
    | 'textInverse'
    | 'textSubtle'
    | 'success'
    | 'error'
}

const style = css<InputProps>`
  border: 1px solid
    ${props => props.theme.colors[props.bordercolor || 'border']};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 1rem;
  padding: 0.75rem;
  width: 100%;
  &:focus {
    border: 1px solid rgb(94, 158, 214);
  }
`

export const Input = styled.input<InputProps>`
  ${style}
`

const MaskedInput = styled(PatternFormat)<InputProps>`
  ${style}
`

export default MaskedInput
