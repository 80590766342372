import { createAsyncThunk, createSlice, unwrapResult } from '@reduxjs/toolkit'
import { navigate } from '@reach/router'
import * as queryString from 'query-string'

import { signInWithCustomToken, signOut } from 'src/utils/firebase'
import { trackEvent } from 'src/utils/metrics'
import apiRequest from 'src/utils/api'
import { setOnboarding } from 'src/features/sharedActions'
import {
  setKycData,
  setKybProvider,
  setKycProvider,
} from 'src/features/verify/verifySlice'
import {
  sendAuthorizedMessage,
  sendErrorMessage,
} from 'src/features/sdkMessaging/sdkMessagingSlice'
import toast from 'react-hot-toast'
import logError, {
  leaveBugsnagBreadcrumb,
  setBugsnagClientUser,
} from 'src/utils/logError'
import { RootState } from 'src/redux/reducer'
import {
  AuthFlow,
  Client,
  OAuthClientPublic,
  UserKycType,
  User,
  UserType,
  VerificationStatus,
  UserStatus,
  KycProvider,
} from 'src/features/auth/types'

export const getClientPublic = createAsyncThunk(
  'auth/getClientPublic',
  async (clientId: string, { dispatch, getState }) => {
    if (!clientId) {
      return null
    }

    const {
      verify: { kybProvider, kycProvider },
    } = getState() as RootState
    const response = (await dispatch(
      apiRequest({
        method: 'get',
        route: `oauth/clients/${clientId}/public`,
      }),
    ).then(unwrapResult)) as OAuthClientPublic

    if (!kybProvider) {
      dispatch(setKybProvider(response.kyb_provider))
    }

    if (!kycProvider) {
      dispatch(setKycProvider(response.kyc_provider))
    }

    return {
      client_id: clientId as string,
      ...(response as {
        debit_enabled: boolean
        is_api_only: boolean
        is_sandbox_phone_numbers_enabled: boolean
        kyb_provider: string
        kyc_provider: string
        name: string
        oauth_logo_url: string
        processor_token_enabled: boolean
        support_email: string
        support_url: string
      }),
    }
  },
)

export const authorize = createAsyncThunk(
  'auth/authorize',
  async (
    {
      clientId,
      redirectUri,
      responseType,
      state,
    }: {
      clientId: string
      redirectUri: string
      responseType: string
      state: string
    },
    { dispatch, getState },
  ) => {
    if (!clientId) {
      toast.error('Invalid configuration.')
      return null
    }

    const { code, error_description: errorDescription } = (await dispatch(
      apiRequest({
        method: 'post',
        route: `oauth/authorize`,
        contentType: 'application/x-www-form-urlencoded',
        data: {
          client_id: clientId,
          redirect_uri: redirectUri,
          response_type: responseType,
        },
      }),
    ).then(unwrapResult)) as { code: string; error_description: string }

    if (!code) {
      let errorMessage = errorDescription || 'Authorization Code not found.'
      // handle 'u' character in python string
      errorMessage = errorMessage.replace("u'", "'")
      const error = new Error(errorMessage)

      dispatch(sendErrorMessage({ error }))

      toast.error(errorMessage)
      throw error
    }

    trackEvent('OAuth Authorize', { client_id: clientId })

    // detect if the redirect uri has existing query parameters
    const oAuthState = state ? `&state=${state}` : ''
    const redirectUriWithData = `${redirectUri}${
      redirectUri && redirectUri.indexOf('?') !== -1 ? '&' : '?'
    }authorization_code=${code}&code=${code}${oAuthState}`

    const authorizationCode = Array.isArray(code) ? code[0] : code

    const {
      sdkMessaging: { iframeOrigin },
    } = getState() as RootState

    if (iframeOrigin) {
      dispatch(
        sendAuthorizedMessage({
          code: authorizationCode,
          oAuthState: state ? `${state}` : '',
        }),
      )
    } else {
      window.location.href = redirectUriWithData
    }
  },
)

export const logout = createAsyncThunk(
  'auth/logout',
  async (
    {
      shouldRedirect,
      clearParams,
    }: {
      shouldRedirect: boolean
      clearParams?: boolean
    },
    { dispatch },
  ) => {
    const { pathname, search } = window.location
    const {
      business,
      client_id: clientId,
      phone,
      redirect_uri: redirectUri,
      user_intent_id: userIntentId,
      bypass_connect: bypassConnect,
      return_to: returnTo,
    } = queryString.parse(search)

    try {
      await dispatch(
        apiRequest({
          method: 'post',
          route: 'sign_out',
        }),
      )
    } catch {
      // ignoring as no token was found to invalidate
    }

    await signOut()

    if (clearParams) {
      return navigate('/')
    }

    if (shouldRedirect) {
      if (clientId && redirectUri) {
        // keep oauth parameters in the url after logout
        const returnTo = `${pathname}${search}`
        const userIntentIdQueryString = userIntentId
          ? `&user_intent_id=${userIntentId}`
          : ''
        const businessQueryString = business ? `&business=true` : ''
        const phoneQueryString = phone ? `&phone=${phone}` : ''
        const bypassConnectString =
          bypassConnect === 'true' ? `&bypass_connect=true` : ''

        const link = `/login?client_id=${clientId}${userIntentIdQueryString}&${businessQueryString}${phoneQueryString}${bypassConnectString}&return_to=${encodeURIComponent(
          returnTo,
        )}`
        window.location.href = link
      } else if (returnTo) {
        window.location.href = `${returnTo}`
      } else {
        window.location.href = '/'
      }
    }
  },
)

interface NavigateProps {
  user: any
  userIntentId?: string
  returnTo?: string
  authFlow?: string
  bypassConnect?: string
}

const navigateUser = createAsyncThunk(
  'auth/navigateUser',
  async (
    { user, userIntentId, returnTo: returnToUrl, bypassConnect }: NavigateProps,
    { getState },
  ) => {
    const {
      auth: { client },
    } = getState() as RootState

    leaveBugsnagBreadcrumb({
      message: 'navigateUser',
      metadata: {
        user,
        userIntentId,
        client,
        returnTo: returnToUrl,
        bypassConnect,
      },
    })

    const isBusinessUser = user.user_type === UserType.BUSINESS

    let showConnectCard = client?.debitEnabled
    if (user.verification_status === VerificationStatus.UNVERIFIED) {
      showConnectCard = false
    }

    const returnToSplit = returnToUrl ? returnToUrl.split('?')[1] : ''
    const returnToParts = returnToSplit ? queryString.parse(returnToSplit) : {}
    const isDebitDirect = returnToParts.debit_direct === 'true'

    let connectScreen = ''
    if (isDebitDirect && showConnectCard) connectScreen = 'cards/'

    const returnToParam = returnToUrl
      ? `?return_to=${encodeURIComponent(returnToUrl)}`
      : ''

    let verifyPath
    if (isBusinessUser) {
      if (user?.needs_beneficial_owner_certification) {
        verifyPath = 'verify/beneficial-owners'
      } else {
        verifyPath = 'verify/business'
      }
    } else {
      verifyPath = 'verify/personal'
    }

    if (
      user.status === UserStatus.REJECTED ||
      user.status === UserStatus.SUSPENDED
    ) {
      navigate(`/rejected${returnToParam}`)
      return
    }

    const skipConnectScreen = !showConnectCard || bypassConnect === 'true'

    if (!user.is_onboarded && !user.has_oauth_token) {
      const clientIdString = client.clientId
        ? `client_id=${client.clientId}&`
        : ''

      if (
        user.verification_status === VerificationStatus.APPROVED ||
        user.verification_status === VerificationStatus.PENDING ||
        user.verification_status === VerificationStatus.MANUAL_REVIEW
      ) {
        if (skipConnectScreen && returnToUrl) {
          navigate(returnToUrl)
        } else {
          navigate(`/${connectScreen}connect${returnToParam}`)
        }
      } else if (
        user.verification_status === VerificationStatus.UNVERIFIED ||
        user.verification_status === VerificationStatus.RETRY
      ) {
        if (skipConnectScreen && returnToUrl) {
          navigate(`/${verifyPath}${returnToParam}`)
        } else {
          navigate(
            `/${verifyPath}?${clientIdString}continue_to=${encodeURIComponent(
              `/${connectScreen}connect${returnToParam}`,
            )}`,
          )
        }
      } else if (user.verification_status === VerificationStatus.DOCUMENT) {
        if (skipConnectScreen && returnToUrl) {
          navigate(
            `/verify/document?continue_to=${encodeURIComponent(returnToUrl)}`,
          )
        } else {
          navigate(
            `/verify/document?${clientIdString}continue_to=${encodeURIComponent(
              `/${connectScreen}connect${returnToParam}`,
            )}`,
          )
        }
      } else if (returnToUrl) {
        navigate(returnToUrl)
      }

      return
    }

    if (returnToUrl) {
      if (
        user.verification_status === VerificationStatus.UNVERIFIED ||
        user.verification_status === VerificationStatus.RETRY
      ) {
        navigate(`/${verifyPath}${returnToParam}`)
      } else if (user.verification_status === VerificationStatus.DOCUMENT) {
        navigate(`/verify/document${returnToParam}`)
      } else {
        navigate(returnToUrl)
      }

      return
    }

    if (window.location.pathname === '/login') {
      navigate('/')
    }
  },
)

export const providerLogin = createAsyncThunk(
  'auth/providerLogin',
  async (
    {
      businessProfileId,
      clientId,
      sessionToken,
      userId,
      userIntentId,
    }: {
      clientId: string
      sessionToken: string
      userIntentId?: string
      userId?: string
      businessProfileId?: string
    },
    { dispatch },
  ) => {
    try {
      const { token } = (await dispatch(
        apiRequest({
          method: 'post',
          route: 'provider_login',
          sessionToken: sessionToken,
          data: {
            client_id: clientId,
            user_intent_id: userIntentId,
            user_id: userId,
            business_profile_id: businessProfileId,
          },
          suppressError: true,
        }),
      ).then(unwrapResult)) as { token: string }

      return token
    } catch (error) {
      logError(error)

      return ''
    }
  },
)

export const clientSandboxLogin = createAsyncThunk(
  'auth/clientSandboxLogin',
  async (
    {
      clientId,
      businessProfileId,
      phone,
      userId,
      userIntentId,
    }: {
      clientId: string
      businessProfileId?: string
      phone: string
      userId?: string
      userIntentId?: string
    },
    { dispatch },
  ) => {
    try {
      const { token } = (await dispatch(
        apiRequest({
          method: 'post',
          route: 'client_sandbox_login',
          data: {
            client_id: clientId,
            business_profile_id: businessProfileId,
            phone,
            user_id: userId,
            user_intent_id: userIntentId,
          },
          suppressError: true,
        }),
      ).then(unwrapResult)) as { token: string }

      return token
    } catch (error) {
      logError(error)
      return ''
    }
  },
)

export const attemptSignInWithCustomToken = createAsyncThunk(
  'auth/attemptSignInWithCustomToken',
  async (
    {
      token,
    }: {
      token: string
    },
    { rejectWithValue },
  ) => {
    try {
      await signInWithCustomToken(token)
    } catch (error) {
      rejectWithValue(error)
    }
  },
)

interface AstraAuthProps {
  authFlow: AuthFlow
  business?: boolean
  businessProfileId?: string
  bypassConnect?: string
  clientId?: string
  returnTo?: string
  userId?: string
  userIntentId?: string
}

export const astraAuth = createAsyncThunk(
  'auth/astraAuth',
  async (
    {
      authFlow,
      business,
      businessProfileId,
      bypassConnect,
      clientId: clientIdParam,
      returnTo,
      userId,
      userIntentId,
    }: AstraAuthProps,
    { dispatch, getState, rejectWithValue },
  ) => {
    const {
      auth: {
        client: { clientId },
        firebaseToken,
        user: { firstName, lastName, email, phone },
      },
    } = getState() as RootState

    leaveBugsnagBreadcrumb({
      message: 'astraAuth',
      metadata: {
        authFlow,
        business,
        businessProfileId,
        bypassConnect,
        clientId,
        phone,
        returnTo,
        userId,
        userIntentId,
      },
    })

    let user: any

    try {
      if (authFlow === AuthFlow.SIGN_UP || userIntentId || businessProfileId) {
        user = await dispatch(
          apiRequest({
            method: 'post',
            route: 'sign_up',
            data: {
              access_token: firebaseToken,
              business_profile_id: businessProfileId,
              email: email,
              first_name: firstName,
              last_name: lastName,
              oauth_client_id_created_by: clientIdParam || clientId,
              phone,
              timezone: 'America/Los_Angeles',
              user_id: userId,
              user_intent_id: userIntentId,
              user_type: business ? UserType.BUSINESS : UserType.PERSONAL,
            },
          }),
        ).then(unwrapResult)
      } else {
        user = await dispatch(
          apiRequest({
            method: 'post',
            route: 'login',
            data: {
              access_token: firebaseToken,
              business_profile_id: businessProfileId,
              client_id: clientId,
              timezone: 'America/Los_Angeles',
              user_id: userId,
              user_intent_id: userIntentId,
              user_type: business ? UserType.BUSINESS : UserType.PERSONAL,
            },
          }),
        ).then(unwrapResult)
      }

      dispatch(setKycData(user))

      const fullName = `${user.first_name} ${user.last_name}`

      // identify user in Heap
      window.heap.identify(user.user_id)
      window.heap.addUserProperties({
        Name: fullName,
        Email: user.email,
        Phone: user.phone,
        Suspended: user.is_suspended,
      })

      setBugsnagClientUser({
        id: user.user_id,
        email: user.email,
        name: fullName,
      })

      dispatch(
        navigateUser({
          user,
          userIntentId,
          returnTo,
          authFlow,
          bypassConnect,
        }),
      )

      if (!user.is_onboarded && user.user_type === UserType.PERSONAL) {
        dispatch(setOnboarding(user.user_id))
      }

      return { user, authFlow }
    } catch (e) {
      const error = e as any
      if (error.code === 401) {
        if (error.description.startsWith('User not signed up')) {
          return rejectWithValue({
            message: 'User is not signed up',
            showSignUpFields: true,
          })
        }

        if (
          error.description ===
            'This user is suspended. Please contact support.' ||
          error.description ===
            'Login failed due to an error in your account. Please contact astra support'
        ) {
          if (
            error.description ===
            'Login failed due to an error in your account. Please contact astra support'
          ) {
            error.description = 'Login failed due to an error in your account.'
          }
          dispatch(logout({ shouldRedirect: false }))
          navigate(
            `/rejected${
              returnTo ? `?return_to=${encodeURIComponent(returnTo)}` : ''
            }`,
          )
          dispatch(sendErrorMessage({ error }))

          throw error
        }
      }
      dispatch(sendErrorMessage({ error }))

      throw error
    }
  },
)

export interface AuthSliceState {
  authFlow: string
  client: Client
  customFirebaseToken: string
  firebaseToken: string
  hasAuthorized: boolean
  isLoggedIn: boolean
  isLoggingIn: boolean
  isLoggingInWithCustomToken: boolean
  isOnboarded: boolean
  isSendingAuthCode: boolean
  isSigningUp: boolean
  showSignUpFields: boolean
  user: User
}

export const initialState: AuthSliceState = {
  authFlow: '',
  client: {
    clientId: '',
    debitEnabled: false,
    isApiOnly: false,
    isSandboxPhoneNumbersEnabled: false,
    kybProvider: '',
    kycProvider: '',
    loading: false,
    logo: '',
    name: '',
    processorTokenEnabled: false,
    supportEmail: '',
    supportUrl: '',
  },
  customFirebaseToken: '',
  firebaseToken: '',
  hasAuthorized: false,
  isLoggedIn: false,
  isLoggingIn: true,
  isLoggingInWithCustomToken: true,
  isOnboarded: false,
  isSendingAuthCode: false,
  isSigningUp: false,
  showSignUpFields: false,
  user: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    kycType: UserKycType.VERIFIED,
    status: UserStatus.UNVERIFIED,
    userId: '',
    userType: '',
    dateOfBirth: '',
    documentStatus: '',
    kycProvider: KycProvider.NONE,
    needsBeneficialOwnerCertification: false,
    verificationStatus: VerificationStatus.UNVERIFIED,
  },
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    firebaseTokenNotFound: state => {
      state.isLoggingIn = false
    },
    setFirebaseToken: (state, action) => {
      state.firebaseToken = action.payload
    },
    setHasAuthorized: (state, action) => {
      state.hasAuthorized = action.payload
    },
    setIsLoggingInWithCustomToken: (state, action) => {
      state.isLoggingInWithCustomToken = action.payload
    },
    setPhoneNumber: (state, action) => {
      state.user.phone = action.payload
    },
    setIsSendingAuthCode: (state, action) => {
      state.isSendingAuthCode = action.payload
    },
    setUserSignupData: (state, action) => {
      state.user = {
        ...state.user,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getClientPublic.pending, state => {
      state.client.loading = true
    })
    builder.addCase(getClientPublic.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.clientId = action.payload.client_id
        state.client.isApiOnly = action.payload.is_api_only
        state.client.debitEnabled = action.payload.debit_enabled
        state.client.kybProvider = action.payload.kyb_provider
        state.client.kycProvider = action.payload.kyc_provider
        state.client.logo = action.payload.oauth_logo_url
        state.client.name = action.payload.name
        state.client.processorTokenEnabled =
          action.payload.processor_token_enabled
        state.client.isSandboxPhoneNumbersEnabled =
          action.payload.is_sandbox_phone_numbers_enabled
        state.client.supportEmail = action.payload.support_email
        state.client.supportUrl = action.payload.support_url
      }
      state.client.loading = false
    })
    builder.addCase(getClientPublic.rejected, state => {
      state.client.loading = false
    })
    builder.addCase(astraAuth.pending, (state, action) => {
      if (action.meta.arg.authFlow === AuthFlow.SIGN_UP) {
        state.isSigningUp = true
      }
    })
    builder.addCase(astraAuth.fulfilled, (state, action) => {
      state.isLoggedIn = true
      state.isLoggingIn = false
      state.isSigningUp = false
      if (action.payload.user) {
        state.isOnboarded = action.payload.user.is_onboarded
        state.user.email = action.payload.user.email
        state.user.firstName = action.payload.user.first_name
        state.user.kycType = action.payload.user.kyc_type
        state.user.lastName = action.payload.user.last_name
        state.user.phone = action.payload.user.phone
        state.user.status = action.payload.user.status
        state.user.userId = action.payload.user.user_id
        state.user.userType = action.payload.user.user_type
      }
    })
    builder.addCase(astraAuth.rejected, (state, action) => {
      state.isLoggingIn = false
      state.isSigningUp = false
      if (action.payload) {
        // @ts-ignore
        state.showSignUpFields = action.payload.showSignUpFields || false
      }
    })
    builder.addCase(setOnboarding.fulfilled, state => {
      state.isOnboarded = true
    })
    builder.addCase(logout.fulfilled, () => {
      return { ...initialState, isLoggingIn: false }
    })
    builder.addCase(providerLogin.pending, state => {
      state.isLoggingInWithCustomToken = true
    })
    builder.addCase(providerLogin.fulfilled, (state, action) => {
      state.customFirebaseToken = action.payload
      state.isLoggingInWithCustomToken = Boolean(action.payload)
    })
    builder.addCase(providerLogin.rejected, state => {
      state.isLoggingInWithCustomToken = false
    })
    builder.addCase(clientSandboxLogin.pending, state => {
      state.isLoggingInWithCustomToken = true
    })
    builder.addCase(clientSandboxLogin.fulfilled, (state, action) => {
      state.customFirebaseToken = action.payload
      state.isLoggingInWithCustomToken = Boolean(action.payload)
    })
    builder.addCase(clientSandboxLogin.rejected, state => {
      state.isLoggingInWithCustomToken = false
    })
    builder.addCase(attemptSignInWithCustomToken.pending, state => {
      state.isLoggingInWithCustomToken = true
    })
    builder.addCase(attemptSignInWithCustomToken.fulfilled, state => {
      state.isLoggingInWithCustomToken = true
    })
    builder.addCase(attemptSignInWithCustomToken.rejected, state => {
      state.isLoggingInWithCustomToken = false
    })
  },
})

export const {
  firebaseTokenNotFound,
  setFirebaseToken,
  setHasAuthorized,
  setIsLoggingInWithCustomToken,
  setIsSendingAuthCode,
  setPhoneNumber,
  setUserSignupData,
} = authSlice.actions

export const selectCustomFirebaseToken = (state: RootState): string =>
  state.auth.customFirebaseToken

export const selectFirebaseToken = (state: RootState): string =>
  state.auth.firebaseToken

export const selectHasAuthorized = (state: RootState): boolean =>
  state.auth.hasAuthorized

export const selectIsApiOnly = (state: RootState): boolean =>
  state.auth.client.isApiOnly

export const selectClientLoading = (state: RootState): boolean =>
  state.auth.client.loading

export const selectIsLoggingIn = (state: RootState): boolean =>
  state.auth.isLoggingIn

export const selectIsLoggingInWithCustomToken = (state: RootState): boolean => {
  return state.auth.isLoggingInWithCustomToken
}
export const selectIsLoggedIn = (state: RootState): boolean =>
  state.auth.isLoggedIn
export const selectIsSigningUp = (state: RootState): boolean =>
  state.auth.isSigningUp
export const selectClient = (state: RootState): typeof initialState.client =>
  state.auth.client
export const selectShowSignUpFields = (state: RootState): boolean =>
  state.auth.showSignUpFields
export const selectUser = (state: RootState): typeof initialState.user =>
  state.auth.user

export const selectIsSendingAuthCode = (state: RootState): boolean =>
  state.auth.isSendingAuthCode

export default authSlice.reducer
